import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: 0 0 0.5rem 0 rgb(136 152 170 / 40%);

  .no-padding {
    padding: 0;
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  > label {
    gap: 0.5rem;
    display: flex;
  }
`;
