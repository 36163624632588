export enum ESolicitImportDestination {
  Financial = 1,
  PersonnelDepartment = 2,
}

export const solicitImportDestinationLang = {
  [ESolicitImportDestination.Financial]: "Financeiro",
  [ESolicitImportDestination.PersonnelDepartment]: "Dep. Pessoal",
};

export enum ESolicitImportDocumentStatus {
  Complete = 1,
  Incomplete = 2,
}

export enum ESolicitImportStatus {
  NotRequested = 0,
  Requested = 1,
  Canceled = 2,
  Approved = 3,
  Rejected = 4,
  Paid = 5,
}

export enum ESolicitImportPaymentMethod {
  Billet = 1,
  CreditCard = 2,
  CurrentAccountTransfer = 3,
  Cash = 4,
  Check = 5,
  DirectDebit = 6,
  Pix = 7,
}

export enum ESolicitImportSteps {
  FILE,
  VALIDATION,
  FINAL,
}

export enum EFuspContractType {
  NotRpa = 0,
  Rpa = 1,
  RpaAndContract = 2,
}
