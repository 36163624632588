import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  IExportMacroCategoryContract,
  IExportMacroCategoryParams,
} from "../../domain/contracts/exportMacroCategoryContract";

export class ExportMacroCategoryService
  implements IExportMacroCategoryContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
    private setServerSideService: ISetServerSideService,
  ) {}

  async exportMacroCategory({
    payload,
    showActivesOnly,
  }: IExportMacroCategoryParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "Downloads/MacroBoxes/Spreadsheets";

    const finalPayload: IPayloadEntity = {
      ...payload,
      start: 0,
      draw: undefined,
      length: undefined,
    };

    if (showActivesOnly) {
      const activesColumnIndex = payload.columns.findIndex(
        column => column.data === "active",
      );

      if (activesColumnIndex !== -1) {
        finalPayload.columns[activesColumnIndex].searchable = true;
        finalPayload.columns[activesColumnIndex].search.value = "true";
      }
    }

    const serverSideId = await this.setServerSideService.setServerSide(
      finalPayload,
    );

    const params = new URLSearchParams({
      body: serverSideId,
    });

    const response = await this.api.download?.(url, {
      params,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers["content-disposition"] ||
      `attachment; filename=Download; filename*=UTF-8''Download`;

    const matches = /filename=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();
    fileName = fileName.replace(/"/g, "");

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
