import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { SearchClassificationAssessmentService } from "../../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../../core/data/services/searchCostCenterService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { ValidateMeasurementAttachmentService } from "../../../../core/data/services/validateMeasurementAttachmentService";
import { IFetchRpaParamOptionsContract } from "../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchClassificationAssessmentContract } from "../../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../../core/domain/contracts/searchCostCenterContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { IValidateMeasurementAttachmentContract } from "../../../../core/domain/contracts/validateMeasurementAttachmentContract";
import { ApprovePaymentRequestService } from "../data/services/approvePaymentRequestService";
import { CheckDateForPaymentService } from "../data/services/checkDateForPaymentService";
import { CheckDocumentNumberService } from "../data/services/checkDocumentNumberService";
import { CheckReviewDocumentNumberService } from "../data/services/checkReviewDocumentNumberService";
import { FetchDocumentStatusService } from "../data/services/fetchDocumentStatusService";
import { FetchFuspContractTypeService } from "../data/services/fetchFuspContractTypeService";
import { FetchPaymentMethodsService } from "../data/services/fetchPaymentMethodsService";
import { FetchPaymentRequestsDestinations } from "../data/services/fetchPaymentRequestDestinationsService";
import { FetchRpaParamOptionsService } from "../data/services/fetchRpaParamOptionsService";
import { GenerateAttachmentRpaService } from "../data/services/generateAttachmentRpaService";
import { GenerateAttachmentVariableAdditionalService } from "../data/services/generateAttachmentVariableAdditionalService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetPaymentRequestForReviewService } from "../data/services/getPaymentRequestForReviewService";
import { GetPaymentRequestService } from "../data/services/getPaymentRequestService";
import { GetProviderByIdService } from "../data/services/getProviderByIdService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { ListActiveCostCentersService } from "../data/services/listActiveCostCentersService";
import { ListAttachmentTypesService } from "../data/services/listAttachmentTypesService";
import { ListCompanyGroupActiveFeesService } from "../data/services/listCompanyGroupActiveFeesService";
import { ListRequesterAttachmentTypesService } from "../data/services/listRequesterAttachmentTypesService";
import { ProviderHasBankingDataService } from "../data/services/providerHasBankingDataService";
import { ProviderHasClassificationAccountService } from "../data/services/providerHasClassificationAccountService";
import { ReprovePaymentRequestService } from "../data/services/reprovePaymentRequestService";
import { SavePaymentRequestService } from "../data/services/savePaymentRequestService";
import { SearchCompanyPaymentAccountsService } from "../data/services/searchCompanyPaymentAccountsService";
import { SearchProviderClassificationAccountByCompanyIdService } from "../data/services/searchProviderClassificationAccountByCompanyIdService";
import { SearchProviderClassificationAccountService } from "../data/services/searchProviderClassificationAccountService";
import { SearchProviderService } from "../data/services/searchProviderService";
import { UpdateAttachmentsBarcodeService } from "../data/services/updateAttachmentsBarcodeService";
import { UpdatePaymentRequestService } from "../data/services/updatePaymentRequestService";
import { UpdateProviderRetainFeeService } from "../data/services/updateProviderRetainFeeService";
import { UploadPaymentRequestAttachmentsService } from "../data/services/uploadPaymentRequestAttachmentService";
import { UploadReviewAttachmentService } from "../data/services/uploadReviewAttachmentService";
import { ValidateAttachmentsService } from "../data/services/validateAttachmentsService";
import { IApprovePaymentRequestContract } from "../domain/contracts/approvePaymentRequestContract";
import { ICheckDateForPaymentContract } from "../domain/contracts/checkDateForPaymentContract";
import { ICheckDocumentNumberContract } from "../domain/contracts/checkDocumentNumberContract";
import { ICheckReviewDocumentNumberContract } from "../domain/contracts/checkReviewDocumentNumberContract";
import { IFetchDocumentStatusContract } from "../domain/contracts/fetchDocumentStatusContract";
import { IFetchFuspContractType } from "../domain/contracts/fetchFuspContractTypeContract";
import { IFetchPaymentMethodsContract } from "../domain/contracts/fetchPaymentMethodsContract";
import { IFetchPaymentRequestDestinationsContract } from "../domain/contracts/fetchPaymentRequestDestinationsContract";
import { IGenerateAttachmentRpaContract } from "../domain/contracts/generateAttachmentRpaContract";
import { IGenerateAttachmentVariableAdditionalContract } from "../domain/contracts/generateAttachmentVariableAdditionalContract";
import { IGetAttachmentsContract } from "../domain/contracts/getAttachmentsContract";
import { IGetPaymentRequestContract } from "../domain/contracts/getPaymentRequestContract";
import { IGetPaymentRequestForReviewContract } from "../domain/contracts/getPaymentRequestForReviewContract";
import { IGetProviderByIdContract } from "../domain/contracts/getProviderByIdContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IListActiveCostCentersContract } from "../domain/contracts/listActiveCostCentersContract";
import { IListCompanyGroupActiveFeesContract } from "../domain/contracts/listCompanyGroupActiveFeesContract";
import { IListRequesterAttachmentTypesContract } from "../domain/contracts/listRequesterAttachmentTypes";
import { IProviderHasBankingDataContract } from "../domain/contracts/providerHasBankingDataContract";
import { IProviderHasClassificationAccountContract } from "../domain/contracts/providerHasClassificationAccountContract";
import { IReprovePaymentRequestContract } from "../domain/contracts/reprovePaymentRequestContract";
import { ISavePaymentRequestContract } from "../domain/contracts/savePaymentRequestContract";
import { ISearchCompanyPaymentAccountsContract } from "../domain/contracts/searchCompanyPaymentAccountsContract";
import { ISearchProviderClassificationAccountByCompanyIdContract } from "../domain/contracts/searchProviderClassificationAccountByCompanyIdContract";
import { ISearchProviderClassificationAccountContract } from "../domain/contracts/searchProviderClassificationAccountContract";
import { ISearchProviderContract } from "../domain/contracts/searchProviderContract";
import { IUpdateAttachmentsBarcodeContract } from "../domain/contracts/updateAttachmentsBarcodeContract";
import { IUpdatePaymentRequestContract } from "../domain/contracts/updatePaymentRequestContract";
import { IUpdateProviderRetainFeeContract } from "../domain/contracts/updateProviderRetainFeeContract";
import { IUploadPaymentRequestAttachmentsContract } from "../domain/contracts/uploadPaymentRequestAttachmentSContract";
import { IUploadReviewAttachmentsContract } from "../domain/contracts/uploadReviewAttachmentsContract";
import { IValidateAttachmentsContract } from "../domain/contracts/validateAttachmentsContract";

export type MakePaymentRequestForm = IFetchPaymentMethodsContract &
  IFetchDocumentStatusContract &
  ISearchProviderClassificationAccountContract &
  ISearchCompanyPaymentAccountsContract &
  ICheckDocumentNumberContract &
  ISearchProviderContract &
  IGetAttachmentsContract &
  IGetStorageFilebyIdContract &
  IListAttachmentTypesContract &
  IUploadReviewAttachmentsContract &
  IUploadPaymentRequestAttachmentsContract &
  IUpdateAttachmentsBarcodeContract &
  IGenerateAttachmentRpaContract &
  IGenerateAttachmentVariableAdditionalContract &
  IFetchRpaParamOptionsContract &
  ISavePaymentRequestContract &
  IGetPaymentRequestForReviewContract &
  IUpdatePaymentRequestContract &
  IListActiveCostCentersContract &
  IProviderHasClassificationAccountContract &
  IGetProviderByIdContract &
  IProviderHasBankingDataContract &
  IReprovePaymentRequestContract &
  IApprovePaymentRequestContract &
  IGetPaymentRequestContract &
  IListRequesterAttachmentTypesContract &
  IFetchPaymentRequestDestinationsContract &
  IValidateAttachmentsContract &
  IListCompanyGroupActiveFeesContract &
  IUpdateProviderRetainFeeContract &
  ICheckReviewDocumentNumberContract &
  ICheckDateForPaymentContract &
  ISearchClassificationAssessmentContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchProjectContract &
  ISearchClassificationUspContract &
  ISearchProviderClassificationAccountByCompanyIdContract &
  IValidateMeasurementAttachmentContract &
  IFetchFuspContractType;

export function makePaymentRequestForm(
  api: IApiService,
  barcodeApi: IApiService,
): MakePaymentRequestForm {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const fetchPaymentMethodsService = new FetchPaymentMethodsService(
    getUserLocalService,
    api,
  );

  const fetchDocumentStatusService = new FetchDocumentStatusService(
    getUserLocalService,
    api,
  );

  const searchCompanyPaymentAccountsService =
    new SearchCompanyPaymentAccountsService(getUserLocalService, api);

  const searchProviderService = new SearchProviderService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountService =
    new SearchProviderClassificationAccountService(getUserLocalService, api);

  const checkDocumentNumberService = new CheckDocumentNumberService(
    getUserLocalService,
    api,
  );

  const fetchRpaParamOptionsService = new FetchRpaParamOptionsService(
    getUserLocalService,
    api,
  );

  const genAttachmentRpaService = new GenerateAttachmentRpaService(
    getUserLocalService,
    api,
  );

  const genAttachmentVariableAdditionalService =
    new GenerateAttachmentVariableAdditionalService(getUserLocalService, api);

  const getAttachmentsService = new GetAttachmentsService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listAttachmentTypesService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const updtAttachmentsBarcodeService = new UpdateAttachmentsBarcodeService(
    getUserLocalService,
    api,
    barcodeApi,
  );

  const uploadReviewAttachmentsService = new UploadReviewAttachmentService(
    getUserLocalService,
    api,
  );

  const uploadPaymentRequestAttachmentsService =
    new UploadPaymentRequestAttachmentsService(getUserLocalService, api);

  const savePaymentRequestService = new SavePaymentRequestService(
    getUserLocalService,
    api,
  );

  const getPaymentRequestForReviewService =
    new GetPaymentRequestForReviewService(getUserLocalService, api);

  const updatePaymentRequestService = new UpdatePaymentRequestService(
    getUserLocalService,
    api,
  );

  const listActiveCostCentersService = new ListActiveCostCentersService(
    getUserLocalService,
    api,
  );

  const providerHasClassificationAccountService =
    new ProviderHasClassificationAccountService(getUserLocalService, api);

  const getProviderByIdService = new GetProviderByIdService(
    getUserLocalService,
    api,
  );

  const providerHasBakingDataService = new ProviderHasBankingDataService(
    getUserLocalService,
    api,
  );

  const reprovePaymentRequestService = new ReprovePaymentRequestService(
    getUserLocalService,
    api,
  );

  const aprovePaymentRequestService = new ApprovePaymentRequestService(
    getUserLocalService,
    api,
  );

  const getPaymentRequestService = new GetPaymentRequestService(
    getUserLocalService,
    api,
  );

  const listRequesterAttachmentTypesService =
    new ListRequesterAttachmentTypesService(getUserLocalService, api);

  const fetchPaymentRequestDestinationsService =
    new FetchPaymentRequestsDestinations(getUserLocalService, api);

  const validateAttachmentsService = new ValidateAttachmentsService(
    getUserLocalService,
    api,
  );

  const listCompanyGroupActiveFeesService =
    new ListCompanyGroupActiveFeesService(getUserLocalService, api);

  const updateProviderRetainFeeService = new UpdateProviderRetainFeeService(
    getUserLocalService,
    api,
  );

  const checkReviewDocumentNumberService = new CheckReviewDocumentNumberService(
    getUserLocalService,
    api,
  );

  const checkDateForPaymentService = new CheckDateForPaymentService(
    getUserLocalService,
    api,
  );

  const searchClassificationssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCentersService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountByCompanyIdService =
    new SearchProviderClassificationAccountByCompanyIdService(
      setServerSideService,
      getUserLocalService,
      api,
    );

  const validateMeasurementAttachmentService =
    new ValidateMeasurementAttachmentService(getUserLocalService, api);

  const fetchFuspTypeSolicitationService = new FetchFuspContractTypeService(
    api,
    getUserLocalService,
  );

  return {
    fetchPaymentMethods() {
      return fetchPaymentMethodsService.fetchPaymentMethods();
    },
    fetchDocumentStatus() {
      return fetchDocumentStatusService.fetchDocumentStatus();
    },
    searchCompanyPaymentAccounts(
      companyGroupId,
      companyId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return searchCompanyPaymentAccountsService.searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchProvider(companyGroupId, search, fetchMinLength, onlyActive = false) {
      return searchProviderService.searchProvider(
        companyGroupId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchProviderClassificationAccount(
      companyGroupId,
      providerId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return searchProviderClassificationAccountService.searchProviderClassificationAccount(
        companyGroupId,
        providerId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    checkDocumentNumber(params) {
      return checkDocumentNumberService.checkDocumentNumber(params);
    },
    fetchRpaParamOptions() {
      return fetchRpaParamOptionsService.fetchRpaParamOptions();
    },
    generateAttachmentRpa(generateAttachmentRpaParams) {
      return genAttachmentRpaService.generateAttachmentRpa(
        generateAttachmentRpaParams,
      );
    },
    generateAttachmentVariableAdditional(accountPayableFormEntity) {
      return genAttachmentVariableAdditionalService.generateAttachmentVariableAdditional(
        accountPayableFormEntity,
      );
    },
    getAttachments(accountPayableFormEntity) {
      return getAttachmentsService.getAttachments(accountPayableFormEntity);
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listAttachmentTypes() {
      return listAttachmentTypesService.listAttachmentTypes();
    },
    updateAttachmentsBarcode(companyGroupId, attachmentFormInput) {
      return updtAttachmentsBarcodeService.updateAttachmentsBarcode(
        companyGroupId,
        attachmentFormInput,
      );
    },
    uploadReviewAttachments(params) {
      return uploadReviewAttachmentsService.uploadReviewAttachments(params);
    },
    uploadPaymentRequestAttachments(params) {
      return uploadPaymentRequestAttachmentsService.uploadPaymentRequestAttachments(
        params,
      );
    },
    savePaymentRequest(formEntity) {
      return savePaymentRequestService.savePaymentRequest(formEntity);
    },
    getPaymentRequestForReview(paymentRequestId) {
      return getPaymentRequestForReviewService.getPaymentRequestForReview(
        paymentRequestId,
      );
    },
    updatePaymentRequest(formEntity) {
      return updatePaymentRequestService.updatePaymentRequest(formEntity);
    },
    listActiveCostCenters(companyGroupId) {
      return listActiveCostCentersService.listActiveCostCenters(companyGroupId);
    },
    providerHasClassificationAccount(companyGroupId, providerId) {
      return providerHasClassificationAccountService.providerHasClassificationAccount(
        companyGroupId,
        providerId,
      );
    },
    getProviderById(providerId) {
      return getProviderByIdService.getProviderById(providerId);
    },
    providerHasBankingData(providerId) {
      return providerHasBakingDataService.providerHasBankingData(providerId);
    },
    reprovePaymentRequest(paymentRequestId, reason) {
      return reprovePaymentRequestService.reprovePaymentRequest(
        paymentRequestId,
        reason,
      );
    },
    approvePaymentRequest(formValues) {
      return aprovePaymentRequestService.approvePaymentRequest(formValues);
    },
    getPaymentRequest(paymentRequestId, companyGroupId) {
      return getPaymentRequestService.getPaymentRequest(
        paymentRequestId,
        companyGroupId,
      );
    },
    listRequesterAttachmentTypes() {
      return listRequesterAttachmentTypesService.listRequesterAttachmentTypes();
    },
    fetchPaymentRequestsDestinations() {
      return fetchPaymentRequestDestinationsService.fetchPaymentRequestsDestinations();
    },
    validateAttachments(attachments) {
      return validateAttachmentsService.validateAttachments(attachments);
    },
    listCompanyGroupActiveFees(companyGroupId) {
      return listCompanyGroupActiveFeesService.listCompanyGroupActiveFees(
        companyGroupId,
      );
    },
    updateProviderRetainFee(params) {
      return updateProviderRetainFeeService.updateProviderRetainFee(params);
    },
    checkReviewDocumentNumber(params) {
      return checkReviewDocumentNumberService.checkReviewDocumentNumber(params);
    },
    checkDateForPayment(params) {
      return checkDateForPaymentService.checkDateForPayment(params);
    },
    searchClassificationAssessment(params) {
      return searchClassificationssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCentersService.searchCostCenter(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchProviderClassificationAccountByCompanyId(
      providerId,
      companyId,
      search,
    ) {
      return searchProviderClassificationAccountByCompanyIdService.searchProviderClassificationAccountByCompanyId(
        providerId,
        companyId,
        search,
      );
    },
    validateMeasurementAttachment(value, measurementAttachment) {
      return validateMeasurementAttachmentService.validateMeasurementAttachment(
        value,
        measurementAttachment,
      );
    },
    fetchFuspContractType() {
      return fetchFuspTypeSolicitationService.fetchFuspContractType();
    },
  };
}
