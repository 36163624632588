import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ExportMacroCategoryService } from "../data/services/exportMacroCategoryService";
import { GetMacroCategoryService } from "../data/services/getMacroCategoryService";
import { ListMacroCategoriesService } from "../data/services/listMacroCategoriesService";
import { SaveMacroCategoryService } from "../data/services/saveMacroCategoryService";
import { ToggleMacroCategoryService } from "../data/services/toggleMacroCategoryService";
import { UpdateMacroCategoryService } from "../data/services/updateMacroCategoryService";
import { IExportMacroCategoryContract } from "../domain/contracts/exportMacroCategoryContract";
import { IGetMacroCategoryContract } from "../domain/contracts/getMacroCategoryContract";
import { IListMacroCategoriesContract } from "../domain/contracts/listMacroCategoriesContract";
import { ISaveMacroCategoryContract } from "../domain/contracts/saveMacroCategoryContract";
import { IToggleMacroCategoryContract } from "../domain/contracts/toggleMacroCategoryContract";
import { IUpdateMacroCategoryContract } from "../domain/contracts/updateMacroCategoryContract";

export type MakeMacroCategory = IListMacroCategoriesContract &
  ISaveMacroCategoryContract &
  IGetMacroCategoryContract &
  IUpdateMacroCategoryContract &
  IToggleMacroCategoryContract &
  IExportMacroCategoryContract;

export function makeMacroCategory(api: IApiService): MakeMacroCategory {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSide = new SetServerSideService(getUserLocalService, api);

  const listMacroCategoriesService = new ListMacroCategoriesService(
    getUserLocalService,
    api,
    setServerSide,
  );

  const saveMacroCategoryService = new SaveMacroCategoryService(
    getUserLocalService,
    api,
  );

  const getMacroCategoryService = new GetMacroCategoryService(
    getUserLocalService,
    api,
  );

  const updateMacroCategoryService = new UpdateMacroCategoryService(
    getUserLocalService,
    api,
  );

  const toggleMacroCategoryService = new ToggleMacroCategoryService(
    getUserLocalService,
    api,
  );

  const exportMacroCategoryService = new ExportMacroCategoryService(
    getUserLocalService,
    api,
    setServerSide,
  );

  return {
    listMacroCategories(params) {
      return listMacroCategoriesService.listMacroCategories(params);
    },
    saveMacroCategory(payload) {
      return saveMacroCategoryService.saveMacroCategory(payload);
    },
    getMacroCategory(id) {
      return getMacroCategoryService.getMacroCategory(id);
    },
    updateMacroCategory(id, payload) {
      return updateMacroCategoryService.updateMacroCategory(id, payload);
    },
    toggleMacroCategory(id, toggleActive) {
      return toggleMacroCategoryService.toggleMacroCategory(id, toggleActive);
    },
    exportMacroCategory(params) {
      return exportMacroCategoryService.exportMacroCategory(params);
    },
  };
}
