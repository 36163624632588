import { ChangeEvent, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { isAfter, isBefore, parse } from "date-fns";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { InputDate } from "../../../../../../core/presentation/components/InputDate";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { EFuspContractType } from "../../../domain/entities/paymentRequestEnums";
import { IPaymentRequestFormEntity } from "../../../domain/entities/paymentRequestFormEntity";
import { Checkbox } from "../../../../../../core/presentation/components/Checkbox";

interface ReviewSectionFuspInformationProps {
  readonly?: boolean;
  typeOfContracts: IEnum[];
}

export function ReviewSectionFuspInformation({
  readonly = false,
  typeOfContracts,
}: ReviewSectionFuspInformationProps) {
  const invalidDate = useDateValidator();
  const form = useFormContext<IPaymentRequestFormEntity>();

  const {
    watch,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const fuspContractType = watch("fuspContractType") as IEnum<{
    key: number;
    value: string;
  }> | null;

  const validateStartDate = useCallback(
    (value: string | null) => {
      if (value === null) return true;

      const finalDate = getValues("fuspContractValidityEndDate");

      if (!finalDate) return true;
      if (value === finalDate) return true;

      const date = parse(value, "dd/MM/yyyy", new Date());
      const dateToCompare = parse(finalDate, "dd/MM/yyyy", new Date());

      return isBefore(date, dateToCompare);
    },
    [getValues],
  );

  const validateEndDate = useCallback(
    (value: string | null) => {
      if (value === null) return true;

      const initialDate = getValues("fuspContractValidityStartDate");
      if (!initialDate) return true;

      if (value === initialDate) return true;

      const date = parse(value, "dd/MM/yyyy", new Date());
      const dateToCompare = parse(initialDate, "dd/MM/yyyy", new Date());

      return isAfter(date, dateToCompare);
    },
    [getValues],
  );

  const isDisabledDates =
    fuspContractType?.key !== EFuspContractType.RpaAndContract;

  return (
    <Card>
      <header>Informações FUSP</header>
      <section>
        <div className="form-row">
          <Controller
            name="fuspPurchaseOrderId"
            rules={{
              validate: {
                onlyDigit: val => val?.replace(/\d/g, "") === "",
                maxLength: val => val.length <= 16,
                max: val => BigInt(val || "") <= Number.MAX_SAFE_INTEGER,
              },
            }}
            render={({ field, fieldState }) => {
              const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                const inputValue = event.target.value;
                const numericValue = inputValue.replace(/\D/g, "");

                field.onBlur();
                field.onChange(numericValue);
              };
              return (
                <label className="col-6 form-control">
                  <span>
                    Nº pedido de compra <small>(opcional)</small>{" "}
                  </span>
                  <input
                    {...field}
                    onChange={onChange}
                    id="fusp-information"
                    placeholder="N° pedido de compra"
                    data-testid="fusp-information"
                  />
                  <InvalidFeedback
                    condition={
                      fieldState?.error?.type === "max" ||
                      fieldState?.error?.type === "maxLength"
                    }
                    message="O número do pedido deve ter no máximo 16 dígitos"
                  />

                  <InvalidFeedback
                    condition={fieldState?.error?.type === "onlyDigit"}
                    message="O n° pedido de compra deve conter apenas números."
                  />
                </label>
              );
            }}
          />
          <Controller
            name="fuspReimbursement"
            render={({ field }) => {
              const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                field.onBlur();
                field.onChange(event);
              };

              return (
                <label className="col-12 form-control">
                  <span>&nbsp;</span>
                  <Checkbox
                    checked={field.value ?? false}
                    onChange={onChange}
                    id="chk-fusp-reimbursement-id"
                    label="Reembolso"
                    disabled={readonly}
                  />
                </label>
              );
            }}
          />
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>Tipo de solicitação</span>
            <Controller
              control={control}
              name="fuspContractType"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, ...rest } }) => {
                const handleChange = (
                  event: ChangeEvent<HTMLSelectElement>,
                ) => {
                  const inputValue = event.target.value;

                  const sel = typeOfContracts.find(
                    option => Number(option.key) === Number(inputValue),
                  );

                  if (sel?.key !== EFuspContractType.RpaAndContract) {
                    setValue("fuspContractValidityStartDate", null);
                    setValue("fuspContractValidityEndDate", null);
                  }

                  onBlur();
                  onChange(sel);
                };

                return (
                  <select
                    {...rest}
                    id="fuspContractTypeId"
                    disabled={readonly}
                    className={errors.fuspContractType ? "isInvalid" : ""}
                    onChange={handleChange}
                    value={rest.value?.key || EFuspContractType.NotRpa}
                  >
                    <option value="" disabled>
                      Selecione um tipo
                    </option>

                    {typeOfContracts.map(option => (
                      <option
                        value={option.key}
                        key={`${option.key}-${option.value}`}
                      >
                        {option.value}
                      </option>
                    ))}
                  </select>
                );
              }}
            />
          </label>
          <label className="col-3 form-control">
            <span>Vigência início</span>
            <InputDate
              {...register("fuspContractValidityStartDate", {
                validate: {
                  required: dtValue => (isDisabledDates ? true : !!dtValue),
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                  isBefore: validateStartDate,
                },
              })}
              id="fuspContractValidityStartDate"
              data-testid="txt-fuspContractValidityStartDate"
              placeholder="00/00/0000"
              type="text"
              disabled={isDisabledDates || readonly}
              className={
                errors?.fuspContractValidityStartDate && !isDisabledDates
                  ? "isInvalid"
                  : ""
              }
            />
            <InvalidFeedback
              condition={
                errors.fuspContractValidityStartDate?.type === "required" &&
                isDisabledDates === false
              }
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={
                errors.fuspContractValidityStartDate?.type === "validDate"
              }
              message="Formato de data inválida"
            />
            <InvalidFeedback
              condition={
                errors.fuspContractValidityStartDate?.type === "isBefore"
              }
              message="Data inicial deve ser menor ou igual a final"
            />
          </label>
          <label className="col-3 form-control">
            <span>Vigência fim</span>
            <InputDate
              {...register("fuspContractValidityEndDate", {
                validate: {
                  required: dtValue => (isDisabledDates ? true : !!dtValue),
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                  isAfter: validateEndDate,
                },
              })}
              id="fuspContractValidityEndDate"
              data-testid="txt-fuspContractValidityEndDate"
              placeholder="00/00/0000"
              type="text"
              disabled={isDisabledDates || readonly}
              className={
                errors?.fuspContractValidityEndDate && !isDisabledDates
                  ? "isInvalid"
                  : ""
              }
            />
            <InvalidFeedback
              condition={
                errors.fuspContractValidityEndDate?.type === "required" &&
                !isDisabledDates
              }
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={
                errors.fuspContractValidityEndDate?.type === "validDate"
              }
              message="Formato de data inválida"
            />
            <InvalidFeedback
              condition={errors.fuspContractValidityEndDate?.type === "isAfter"}
              message="Data final deve ser maior ou igual a inicial"
            />
          </label>
        </div>
      </section>
    </Card>
  );
}
