import { ChangeEvent, useCallback, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";

import { FaCaretDown, FaFileExcel, FaPlus } from "react-icons/fa";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { Container, HeaderContainer, Wrapper } from "./styles";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IMacroCategoryEntity } from "../../../domain/entities/macroCategoryEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { useMacroCategoryGrid } from "../../hooks/useMacroCategoryGrid";
import {
  makeMacroCategory,
  MakeMacroCategory,
} from "../../../main/makeMacroCategory";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { MacroCategoryFormModal } from "../MacroCategoryFormModal";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ExportingModalContent } from "../../../../core/presentation/components/ExportingModalContent";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";

interface MacroCategoryPageProps {
  useMacroCategory: MakeMacroCategory;
}

interface MacroCategoryPageState {
  search: string;
  isLoading: boolean;
  globalFilter: string;
  isOpenMacroCategoryModal: boolean;
  currentMacroCategoryId: string;
  showActivesOnly: boolean;
  pfsEvent: IPFSEventEntity;
  data?: IResponseEntity<IMacroCategoryEntity[]>;
}

export function MacroCategoryPage({
  useMacroCategory,
}: MacroCategoryPageProps) {
  const { listMacroCategories, toggleMacroCategory, exportMacroCategory } =
    useMacroCategory;

  const dialog = useSoulDialog();
  const table = useRef<ISimpleTableHandle>(null);
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();

  const [state, setState] = useState<MacroCategoryPageState>({
    search: "",
    isLoading: false,
    globalFilter: "",
    isOpenMacroCategoryModal: false,
    currentMacroCategoryId: "",
    showActivesOnly: false,
    pfsEvent: new PFSEventEntity(),
  });

  const openMacroCategoryModal = useCallback((currentId = "") => {
    setState(prevState => ({
      ...prevState,
      currentMacroCategoryId: currentId,
      isOpenMacroCategoryModal: true,
    }));
  }, []);

  const closeMacroCategoryModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isOpenMacroCategoryModal: false,
      currentMacroCategoryId: "",
    }));
    table.current?.reload();
  }, []);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setState(prevState => ({
        ...prevState,
        search: e.target.value,
      }));
      debounceTime(() => {
        setState(prevState => ({
          ...prevState,
          globalFilter: e.target.value,
        }));
      }, 700);
    },
    [debounceTime],
  );

  const handleToggle = useCallback(
    async (currentId, toggleActive) => {
      toggleMacroCategory(currentId, toggleActive);

      table.current?.reload();
    },
    [toggleMacroCategory],
  );

  const { columns } = useMacroCategoryGrid({
    openEditModal: openMacroCategoryModal,
    toggle: handleToggle,
  });

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setState(prevState => ({
        ...prevState,
        isLoading: true,
        pfsEvent: _pfsEvent,
      }));

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listMacroCategories({
          payload,
          showActivesOnly: state.showActivesOnly,
        });

        setState(prevState => ({
          ...prevState,
          data: response,
        }));
      } catch (err) {
        return;
      } finally {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
        ReactTooltip.rebuild();
      }
    },
    [columns, listMacroCategories, generatePayload, state.showActivesOnly],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      showActivesOnly: value,
    }));

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(state.pfsEvent, columns as ISimpleColumn[]);

    try {
      await exportMacroCategory({
        payload,
        showActivesOnly: state.showActivesOnly,
      });
    } finally {
      dialog.close();
    }
  };

  const allowedProfiles = useAllowedProfiles();

  return (
    <Container>
      <Page>
        <HeaderContainer>
          <InputSearch
            id="txt-search"
            value={state.search}
            onChange={handleSearchChange}
          />

          <Wrapper>
            <label>
              <Toggle
                id="btn-toggle-invalid"
                value={state.showActivesOnly}
                data-testid="btn-toggle-invalid"
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>

            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                type="button"
                className="dropdown-item"
                onClick={handleExportSheet}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
            </SoulDropdown>

            {allowedProfiles(
              EUserProfile.master,
              EUserProfile.supervisor,
              EUserProfile.financialManagement,
            ) ? (
              <button
                type="button"
                className="default-button"
                onClick={() => openMacroCategoryModal()}
              >
                <FaPlus /> Adicionar Macrocategoria
              </button>
            ) : null}
          </Wrapper>
        </HeaderContainer>

        <MacroCategoryFormModal
          isOpen={state.isOpenMacroCategoryModal}
          onRequestClose={closeMacroCategoryModal}
          currentId={state.currentMacroCategoryId}
          useMacroCategory={useMacroCategory}
        />
        <article className="no-padding">
          <SimpleTable<IMacroCategoryEntity>
            tableRef={table}
            data={state.data}
            loading={state.isLoading}
            globalFilter={state.globalFilter}
            columns={columns}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface MacroCategoryPageFactoryProps {
  api: IApiService;
}

export function MacroCategoryPageFactory({
  api,
}: MacroCategoryPageFactoryProps) {
  return <MacroCategoryPage useMacroCategory={makeMacroCategory(api)} />;
}
