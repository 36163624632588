import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../advTable/domain/entities/advTableColumn";
import { EEnumListBoxOptions } from "../../../../advTable/presentation/components/ColumnFilterEnum";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  approvalStatusLang,
  costCenterDocumentStatusLang,
  costCenterPaymentMethodLang,
  costCenterReportOriginLang,
  costCenterReportStatusLang,
  costCenterReportTypeLang,
  EApprovalStatus,
  ECostCenterReportStatus,
  ECostCenterReportType,
  EDocumentStatus,
  EPaymentMethod,
  EReportOrigin,
  EReturnStatus,
  ICostCenterReportEntity,
  returnStatusLang,
} from "../../domain/entities/costCenterReportEntity";
import { MakeCostCenterReport } from "../../main/makeCostCenterReport";
import { BadgeType } from "../components/BadgeType/styles";

export enum EEditModalMode {
  edit,
  readonly,
}

interface IUseCostCenterReportGridParams {
  useCostCenterReport: MakeCostCenterReport;
}

export function useCostCenterReportGrid({
  useCostCenterReport,
}: IUseCostCenterReportGridParams) {
  const originMap: Record<EReportOrigin, string> = useMemo(
    () => ({
      [EReportOrigin.OpeningBalance]: "",
      [EReportOrigin.accountPayable]: "(rateio)",
      [EReportOrigin.accountReceivable]: "(parcela)",
      [EReportOrigin.transaction]: "",
    }),
    [],
  );

  const {
    searchUser,
    searchCostCenter,
    searchClassificationAccount,
    searchClassificationAssessment,
    searchClassificationUsp,
    searchCompany,
    searchCompetency,
    searchPaymentAccount,
    searchProject,
    searchCustomerProvider,
    searchCustomerProviderByCorporationName,
    searchMacroCategories,
  } = useCostCenterReport;
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Origem",
        field: "originDescription",
        searchable: true,
        orderable: true,
        sortField: "origin",
        searchField: "origin",
        className: "text-truncate",
        columnType: EColumnType.enum,
        enumObject: EReportOrigin,
        enumLang: costCenterReportOriginLang,
        width: "130px",
      },
      {
        header: "Tipo",
        field: "typeDescription",
        searchable: false,
        orderable: true,
        sortField: "type",
        searchField: "type",
        columnType: EColumnType.enum,
        enumObject: ECostCenterReportType,
        enumLang: costCenterReportTypeLang,
        className: "text-truncate",
        width: "152px",
        bodyTemplate(rowData: ICostCenterReportEntity) {
          const { type, typeDescription } = rowData;
          return (
            <BadgeType title={typeDescription} type={type}>
              {typeDescription}
            </BadgeType>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | ECostCenterReportType;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const type = value as ECostCenterReportType;
          return (
            <BadgeType title={label} type={type}>
              {label}
            </BadgeType>
          );
        },
      },
      {
        header: "Status aprovação",
        field: "approvalStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "approvalStatus",
        searchField: "approvalStatus",
        className: "text-truncate",
        columnType: EColumnType.enum,
        enumObject: EApprovalStatus,
        enumLang: approvalStatusLang,
        width: "164px",
      },
      {
        header: "Valor do lançamento",
        field: "totalValueString",
        searchField: "totalValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "215px",
        className: "text-truncate",
      },
      {
        header: "Valor da operação",
        field: "operationValueString",
        sortField: "operationValue",
        searchField: "operationValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "198px",
        className: "text-truncate",
        bodyTemplate(rowData: ICostCenterReportEntity) {
          const { operationValueString, origin } = rowData;
          const valueDescription = originMap[origin];

          return (
            <div
              className="cell-truncate"
              title={`${operationValueString} ${valueDescription}`}
            >
              {operationValueString}{" "}
              {valueDescription && (
                <small className="text-muted">{valueDescription}</small>
              )}
            </div>
          );
        },
      },
      {
        header: "Centro de custo",
        field: "costCenterName",
        searchField: "costCenterId",
        className: "text-truncate",
        width: "180px",
        orderable: true,
        searchable: true,
        columnType: EColumnType.relationship,
        async getList(search = "", fetchMinLength = 10) {
          return searchCostCenter({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Nº da parcela",
        orderable: false,
        field: "parcelNumber",
        width: "168px",
        className: "text-truncate",
        columnType: EColumnType.integer,
        bodyTemplate(rowData: ICostCenterReportEntity) {
          const { parcelNumber, numberOfParcels } = rowData;
          const formatedParcelNumber = `${parcelNumber}/${numberOfParcels}`;

          return (
            <div title={formatedParcelNumber} className="cell-truncate">
              {formatedParcelNumber}
            </div>
          );
        },
      },
      { field: "numberOfParcels", hidden: true },
      {
        header: "Porcentagem",
        orderable: true,
        searchable: true,
        field: "percentageString",
        sortField: "percentage",
        searchField: "percentage",
        columnType: EColumnType.decimal,
        width: "162px",
        className: "text-truncate",
      },
      {
        header: "Cliente / Fornecedor",
        field: "customerProviderName",
        searchField: "customerProviderId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchCustomerProvider({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Cliente / Fornecedor (razão social)",
        field: "customerProviderCorporationName",
        searchField: "customerProviderId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchCustomerProviderByCorporationName({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
        headerTemplate() {
          return (
            <>
              Cliente / Fornecedor <br />
              <small>(razão social)</small>
            </>
          );
        },
      },
      {
        header: "Cidade cliente / fornecedor",
        orderable: false,
        field: "customerProviderCityState",
        className: "text-truncate",
        width: "250px",
      },
      {
        header: "Nº documento",
        orderable: false,
        field: "documentNumber",
        className: "text-truncate",
        width: "168px",
      },
      {
        header: "Serial",
        orderable: false,
        field: "serial",
        className: "text-truncate",
        width: "150px",
      },
      {
        header: "TID",
        field: "creditCardLimitTransactionId",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Descrição",
        orderable: false,
        field: "description",
        className: "text-truncate",
        width: "140px",
      },
      // descricao do rateio
      {
        header: "Descrição do rateio",
        orderable: false,
        field: "observation",
        className: "text-truncate",
        width: "204px",
        bodyTemplate(rowData: ICostCenterReportEntity) {
          const { observation = "" } = rowData;
          return <span title={observation}>{observation}</span>;
        },
      },
      {
        header: "Lançado por",
        field: "userNameCreated",
        searchable: true,
        orderable: true,
        searchField: "userCreated",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Status",
        field: "statusDescription",
        searchable: true,
        orderable: true,
        sortField: "status",
        searchField: "status",
        columnType: EColumnType.enum,
        enumObject: ECostCenterReportStatus,
        enumLang: costCenterReportStatusLang,
        width: "124px",
        className: "text-truncate",
      },
      {
        header: "Status devolução",
        field: "returnStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "returnStatus",
        searchField: "returnStatus",
        columnType: EColumnType.enum,
        enumObject: EReturnStatus,
        enumLang: returnStatusLang,
        width: "210px",
        className: "text-truncate",
      },
      {
        header: "Status da documentação",
        field: "documentStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "documentStatus",
        searchField: "documentStatus",
        columnType: EColumnType.enum,
        enumObject: EDocumentStatus,
        enumLang: costCenterDocumentStatusLang,
        width: "230px",
        className: "text-truncate",
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EPaymentMethod,
        enumLang: costCenterPaymentMethodLang,
        className: "text-truncate",
        width: "216px",
      },
      {
        header: "Data da operação",
        field: "operationDateString",
        searchable: true,
        orderable: true,
        searchField: "operationDate",
        sortField: "operationDate",
        columnType: EColumnType.date,
        width: "186px",
        className: "text-truncate",
      },
      {
        header: "Data de emissão",
        field: "issueDateString",
        searchable: true,
        orderable: true,
        searchField: "issueDate",
        sortField: "issueDate",
        columnType: EColumnType.date,
        width: "176px",
        className: "text-truncate",
      },
      {
        header: "Data de lançamento",
        field: "dateCreatedString",
        searchable: true,
        orderable: true,
        searchField: "dateCreated",
        sortField: "dateCreated",
        columnType: EColumnType.date,
        width: "204px",
        className: "text-truncate",
      },
      {
        header: "Empresa",
        field: "companyName",
        orderable: true,
        searchable: true,
        searchField: "companyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "") {
          return searchCompany({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: 50,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Classificação contábil",
        field: "classificationAccountName",
        orderable: true,
        searchable: true,
        searchField: "classificationAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "212px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAccount({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Classificação USP",
        field: "classificationUspName",
        orderable: true,
        searchable: true,
        searchField: "classificationUspId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationUsp({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Competência",
        field: "competencyName",
        orderable: true,
        searchable: true,
        searchField: "competencyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "158px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompetency({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Conta de pagamento",
        field: "paymentAccountName",
        orderable: true,
        searchable: true,
        searchField: "paymentAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 10) {
          return searchPaymentAccount({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Projeto",
        field: "projectName",
        orderable: true,
        searchable: true,
        searchField: "projectId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "", fetchMinLength = 10) {
          return searchProject({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Macrocategorias",
        field: "macroBoxName",
        orderable: true,
        searchable: true,
        width: "170px",
        className: "text-truncate",
        searchField: "macroBoxId",
        columnType: EColumnType.relationship,
        async getList(search = "", fetchMinLength = 10) {
          return searchMacroCategories({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Classificação de rateio",
        field: "classificationAssessmentName",
        orderable: true,
        searchable: true,
        searchField: "classificationAssessmentId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "218px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAssessment({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Data de Aprovação",
        field: "approvalDateString",
        searchable: true,
        orderable: true,
        searchField: "approvalDate",
        sortField: "approvalDate",
        columnType: EColumnType.date,
        width: "186px",
        className: "text-truncate",
      },
      {
        header: "Usuário de Aprovação",
        field: "approvalUserName",
        searchable: true,
        orderable: true,
        searchField: "approvalUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "200px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data de modificação",
        field: "dateModifiedString",
        searchable: true,
        orderable: true,
        searchField: "dateModified",
        sortField: "dateModified",
        columnType: EColumnType.date,
        width: "218px",
        className: "text-truncate",
      },
      {
        header: "Atualizado por",
        field: "userNameModified",
        searchable: true,
        orderable: true,
        searchField: "userModified",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "186px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      { field: "originBarcode", hidden: true },
      { field: "totalValue", hidden: true },
      { field: "originId", hidden: true },
      { field: "origin", hidden: true },
      { field: "type", hidden: true },
    ].map(colParams => new SimpleColumn(colParams));
  }, [
    id,
    originMap,
    searchClassificationAccount,
    searchClassificationAssessment,
    searchClassificationUsp,
    searchCompany,
    searchCompetency,
    searchCostCenter,
    searchCustomerProvider,
    searchCustomerProviderByCorporationName,
    searchPaymentAccount,
    searchProject,
    searchUser,
    searchMacroCategories,
  ]);

  return {
    columns,
  };
}
