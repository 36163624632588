import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { FetchCommercialProposalEnums } from "../../../../core/data/services/fetchCommercialProposalEnumsService";
import { FetchRpaParamOptionsService } from "../../../../core/data/services/fetchRpaParamOptionsService";
import { GenerateCommercialProposalAttachmentService } from "../../../../core/data/services/generateCommercialProposalAttachmentService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { ListAttachmentTypesService } from "../../../../core/data/services/listAttachmentTypesService";
import { SearchClassificationAssessmentService } from "../../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../../core/data/services/searchCostCenterService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { IFetchCommercialProposalEnumsContract } from "../../../../core/domain/contracts/fetchCommercialProposalEnumsContract";
import { IFetchRpaParamOptionsContract } from "../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { IGenerateCommercialProposalAttachmentContract } from "../../../../core/domain/contracts/generateCommercialProposalAttachmentContract";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchClassificationAssessmentContract } from "../../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../../core/domain/contracts/searchCostCenterContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { BulkUploadAttachmentService } from "../data/services/bulkUploadAttachmentsService";
import { CheckDocumentNumberService } from "../data/services/checkDocumentNumberService";
import { FetchDocumentStatusService } from "../data/services/fetchDocumentStatusService";
import { FetchFuspContractTypeService } from "../data/services/fetchFuspContractTypeService";
import { FetchPaymentMethodsService } from "../data/services/fetchPaymentMethodsService";
import { GenerateMultipleCommercialProposalAttachmentsService } from "../data/services/generateMultipleCommercialProposalAttachmentsService";
import { GenerateMultipleRpaAttachmentsService } from "../data/services/generateMultipleRpaAttachmentsService";
import { GenerateMultipleVariableAdditionalAttachmentsService } from "../data/services/generateMultipleVariableAdditionalAttachmentsService";
import { GenerateRpaAttachmentService } from "../data/services/generateRpaAttachmentService";
import { GenerateVariableAdditionalAttachmentService } from "../data/services/generateVariableAdditionalAttachmentService";
import { GetDebtImportTemplateService } from "../data/services/getDebtImportTemplateService";
import { GetProviderByIdService } from "../data/services/getProviderByIdService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { ProviderHasBankingDataService } from "../data/services/providerHasBankingDataService";
import { ProviderHasClassificationAccountService } from "../data/services/providerHasClassificationAccountService";
import { SaveDebtImportFileService } from "../data/services/saveDebtImportFileService";
import { SaveImportDataService } from "../data/services/saveImportDataService";
import { SearchCompanyPaymentAccountsService } from "../data/services/searchCompanyPaymentAccountsService";
import { SearchProviderClassificationAccountByCompanyIdService } from "../data/services/searchProviderClassificationAccountByCompanyIdService";
import { SearchProviderClassificationAccountService } from "../data/services/searchProviderClassificationAccountContract";
import { SearchProvidersService } from "../data/services/searchProvidersService";
import { UpdateAttachmentsBarcodeService } from "../data/services/updateAttachmentsBarcodeService";
import { UploadAttachmentService } from "../data/services/uploadAttachmentService";
import { ValidateImportDataService } from "../data/services/validateImportDataService";
import { IBulkUploadAttachmentsContract } from "../domain/contracts/bulkUploadAttachments";
import { ICheckDocumentNumberContract } from "../domain/contracts/checkDocumentNumberContract";
import { IFetchDocumentStatusContract } from "../domain/contracts/fetchDocumentStatusContract";
import { IFetchFuspContractType } from "../domain/contracts/fetchFuspContractTypeContract";
import { IFetchPaymentMethodsContract } from "../domain/contracts/fetchPaymentMethodsContract";
import { IGenerateMultipleCommercialProposalAttachmentsContract } from "../domain/contracts/generateMultipleCommercialProposalAttachmentsContract";
import { IGenerateMultipleRpaAttachmentsContract } from "../domain/contracts/generateMultipleRpaAttachmentsContract";
import { IGenerateMultipleVariableAdditionalAttachmentsContract } from "../domain/contracts/generateMultipleVariableAdditionalAttachmentsContract";
import { IGenerateRpaAttachmentContract } from "../domain/contracts/generateRpaAttachmentContract";
import { IGenerateVariableAdditionalAttachmentContract } from "../domain/contracts/generateVariableAdditionalAttachmentContract";
import { IGetDebtImportTemplateContract } from "../domain/contracts/getDebtImportTemplateContract";
import { IGetProviderByIdContract } from "../domain/contracts/getProviderByIdContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IProviderHasBankingDataContract } from "../domain/contracts/providerHasBankingDataContract";
import { IProviderHasClassificationAccountContract } from "../domain/contracts/providerHasClassificationAccountContract";
import { ISaveDebtImportFileContract } from "../domain/contracts/saveDebtImportFileContract";
import { ISaveImportDataContract } from "../domain/contracts/saveImportDataContract";
import { ISearchCompanyPaymentAccountsContract } from "../domain/contracts/searchCompanyPaymentAccountsContract";
import { ISearchProviderClassificationAccountByCompanyIdContract } from "../domain/contracts/searchProviderClassificationAccountByCompanyIdContract";
import { ISearchProviderClassificationAccountContract } from "../domain/contracts/searchProviderClassificationAccountContract";
import { ISearchProvidersContract } from "../domain/contracts/searchProvidersContract";
import { IUpdateAttachmentsBarcodeContract } from "../domain/contracts/updateAttachmentsBarcodeContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";
import { IValidateImportDataContract } from "../domain/contracts/validateImportDataContract";

export type MakeDebtImport = IGetDebtImportTemplateContract &
  ISaveDebtImportFileContract &
  IFetchPaymentMethodsContract &
  IFetchDocumentStatusContract &
  ISearchProviderClassificationAccountContract &
  ISearchCompanyPaymentAccountsContract &
  ICheckDocumentNumberContract &
  ISearchProvidersContract &
  IUploadAttachmentsContract &
  IGenerateRpaAttachmentContract &
  IGenerateMultipleRpaAttachmentsContract &
  IGenerateVariableAdditionalAttachmentContract &
  IGenerateMultipleVariableAdditionalAttachmentsContract &
  IGetStorageFilebyIdContract &
  IListAttachmentTypesContract &
  IUpdateAttachmentsBarcodeContract &
  IFetchRpaParamOptionsContract &
  IBulkUploadAttachmentsContract &
  IValidateImportDataContract &
  IGetProviderByIdContract &
  IProviderHasBankingDataContract &
  IProviderHasClassificationAccountContract &
  ISaveImportDataContract &
  ISearchClassificationAssessmentContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchProjectContract &
  ISearchClassificationUspContract &
  ISearchProviderClassificationAccountByCompanyIdContract &
  IFetchCommercialProposalEnumsContract &
  IGenerateCommercialProposalAttachmentContract &
  IGenerateMultipleCommercialProposalAttachmentsContract &
  IFetchFuspContractType;

export function makeDebtImport(
  api: IApiService,
  barcodeApi: IApiService,
): MakeDebtImport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getDebtImportTemplateService = new GetDebtImportTemplateService(
    getUserLocalService,
    api,
  );

  const saveDebtImportFileService = new SaveDebtImportFileService(
    getUserLocalService,
    api,
  );

  const fetchPaymentMethodsService = new FetchPaymentMethodsService(
    getUserLocalService,
    api,
  );

  const fetchDocumentStatusService = new FetchDocumentStatusService(
    getUserLocalService,
    api,
  );

  const searchCompanyPaymentAccountsService =
    new SearchCompanyPaymentAccountsService(getUserLocalService, api);

  const searchProviderService = new SearchProvidersService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountService =
    new SearchProviderClassificationAccountService(getUserLocalService, api);

  const checkDocumentNumberService = new CheckDocumentNumberService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listAttachmentTypesService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const uploadAttachmentService = new UploadAttachmentService(
    getUserLocalService,
    api,
  );

  const updateAttachmentsBarcodeService = new UpdateAttachmentsBarcodeService(
    getUserLocalService,
    api,
    barcodeApi,
  );

  const fetchRpaParamOptionsService = new FetchRpaParamOptionsService(
    getUserLocalService,
    api,
  );

  const generateRpaAttachmentService = new GenerateRpaAttachmentService(
    getUserLocalService,
    api,
  );

  const generateVariableAdditionalAttachmentService =
    new GenerateVariableAdditionalAttachmentService(getUserLocalService, api);

  const generateMultipleRpaAttachmentsService =
    new GenerateMultipleRpaAttachmentsService(getUserLocalService, api);

  const generateMultipleVariableAdditionalAttachmentsService =
    new GenerateMultipleVariableAdditionalAttachmentsService(
      getUserLocalService,
      api,
    );

  const bulkUploadAttachments = new BulkUploadAttachmentService(
    getUserLocalService,
    api,
  );

  const validateImportDataService = new ValidateImportDataService(
    getUserLocalService,
    api,
  );

  const providerHasClassificationAccountService =
    new ProviderHasClassificationAccountService(getUserLocalService, api);

  const getProviderByIdService = new GetProviderByIdService(
    getUserLocalService,
    api,
  );

  const providerHasBankingDataService = new ProviderHasBankingDataService(
    getUserLocalService,
    api,
  );

  const saveImportDataService = new SaveImportDataService(
    getUserLocalService,
    api,
  );

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountByCompanyIdService =
    new SearchProviderClassificationAccountByCompanyIdService(
      setServerSideService,
      getUserLocalService,
      api,
    );

  const fetchCommercialProposalEnumsService = new FetchCommercialProposalEnums(
    getUserLocalService,
    api,
  );

  const generateCommercialProposalAttachmentService =
    new GenerateCommercialProposalAttachmentService(getUserLocalService, api);

  const generateMultipleCommercialProposalAttachmentsService =
    new GenerateMultipleCommercialProposalAttachmentsService(
      getUserLocalService,
      api,
    );
  const fetchFuspContractTypeService = new FetchFuspContractTypeService(
    api,
    getUserLocalService,
  );

  return {
    getDebtImportTemplate() {
      return getDebtImportTemplateService.getDebtImportTemplate();
    },
    saveDebtImportFile(file) {
      return saveDebtImportFileService.saveDebtImportFile(file);
    },
    fetchPaymentMethods() {
      return fetchPaymentMethodsService.fetchPaymentMethods();
    },
    fetchDocumentStatus() {
      return fetchDocumentStatusService.fetchDocumentStatus();
    },
    checkDocumentNumber({
      companyGroupId,
      documentNumber,
      providerId,
      accountPayableId: payableId,
    }) {
      return checkDocumentNumberService.checkDocumentNumber({
        companyGroupId,
        documentNumber,
        providerId,
        accountPayableId: payableId,
      });
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listAttachmentTypes() {
      return listAttachmentTypesService.listAttachmentTypes();
    },
    uploadAttachments(
      accountPayableId,
      attachmentList,
      uploadProgressCallback,
    ) {
      return uploadAttachmentService.uploadAttachments(
        accountPayableId,
        attachmentList,
        uploadProgressCallback,
      );
    },
    updateAttachmentsBarcode(companyGroupId, attachmentFormInput) {
      return updateAttachmentsBarcodeService.updateAttachmentsBarcode(
        companyGroupId,
        attachmentFormInput,
      );
    },
    fetchRpaParamOptions() {
      return fetchRpaParamOptionsService.fetchRpaParamOptions();
    },
    generateRpaAttachment(payload) {
      return generateRpaAttachmentService.generateRpaAttachment(payload);
    },
    generateVariableAdditionalAttachment(payload) {
      return generateVariableAdditionalAttachmentService.generateVariableAdditionalAttachment(
        payload,
      );
    },
    generateMultipleRpaAttachments(rpaOptions, importData) {
      return generateMultipleRpaAttachmentsService.generateMultipleRpaAttachments(
        rpaOptions,
        importData,
      );
    },
    generateMultipleVariableAdditionalAttachments(importData) {
      return generateMultipleVariableAdditionalAttachmentsService.generateMultipleVariableAdditionalAttachments(
        importData,
      );
    },
    bulkUploadAttachments(debtImportIds, attachmentList) {
      return bulkUploadAttachments.bulkUploadAttachments(
        debtImportIds,
        attachmentList,
      );
    },
    validateImportData(data) {
      return validateImportDataService.validateImportData(data);
    },
    searchCompanyPaymentAccounts(
      companyGroupId,
      companyId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return searchCompanyPaymentAccountsService.searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchProviders(search, companyGroupId, active) {
      return searchProviderService.searchProviders(
        search,
        companyGroupId,
        active,
      );
    },
    searchProviderClassificationAccount(
      companyGroupId,
      providerId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return searchProviderClassificationAccountService.searchProviderClassificationAccount(
        companyGroupId,
        providerId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    getProviderById(providerId) {
      return getProviderByIdService.getProviderById(providerId);
    },
    providerHasBankingData(providerId) {
      return providerHasBankingDataService.providerHasBankingData(providerId);
    },
    providerHasClassificationAccount(companyGoupId, providerId) {
      return providerHasClassificationAccountService.providerHasClassificationAccount(
        companyGoupId,
        providerId,
      );
    },
    saveImportData(importData) {
      return saveImportDataService.saveImportData(importData);
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchProviderClassificationAccountByCompanyId(
      providerId,
      companyId,
      search,
    ) {
      return searchProviderClassificationAccountByCompanyIdService.searchProviderClassificationAccountByCompanyId(
        providerId,
        companyId,
        search,
      );
    },
    fetchCommercialProposalEnums() {
      return fetchCommercialProposalEnumsService.fetchCommercialProposalEnums();
    },
    generateCommercialProposalAttachment(params) {
      return generateCommercialProposalAttachmentService.generateCommercialProposalAttachment(
        params,
      );
    },
    generateMultipleCommercialProposalAttachments(params, importData) {
      return generateMultipleCommercialProposalAttachmentsService.generateMultipleCommercialProposalAttachments(
        params,
        importData,
      );
    },
    fetchFuspContractType() {
      return fetchFuspContractTypeService.fetchFuspContractType();
    },
  };
}
