import { ReactNode, useMemo } from "react";
import styled from "styled-components";

const Container = styled.div`
  .error-card {
    box-sizing: border-box;
    display: block;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 12.5rem;
    overflow-y: auto;
    padding: 1.5rem 0.5rem;
    text-align: left;
    border: 1px solid #f2f2f2;
    border-radius: 0.375rem;

    ul {
      text-align: left;
      list-style: none;
      padding-left: 1.5rem;
      padding-right: 1rem;

      li {
        text-align: left;
        font-size: 0.875rem;
        color: var(--color-danger);
        background-color: var(--color-danger-background);
        border-radius: 0.375rem;
        padding: 0.5rem;

        & + li {
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

interface IExportSheetFuspSendingFailureDIalogContentProps {
  error: string | string[];
  isList?: boolean;
  message?: ReactNode;
}

export function ExportSheetFuspSendingFailureDIalogContent({
  error = "",
  isList = false,
  message = "",
}: IExportSheetFuspSendingFailureDIalogContentProps) {
  const dialogBody = useMemo(() => {
    if (message) {
      return message;
    }

    return (
      <>
        <div>
          Houve <b>falha no envio</b> de alguns lançamentos e seus status serão
          atualizados para &ldquo;Falha de envio&rdquo;.
        </div>

        <br />

        <div>
          Após a <b>correção</b>, efetue o envio dos lançamentos novamente.
        </div>
      </>
    );
  }, [message]);

  return (
    <Container>
      <div>{dialogBody}</div>

      <br />

      <div className="error-card">
        {!isList && error}
        {isList && (
          <ul>
            {(error as string[]).map(msg => {
              return <li key={msg}>{msg}</li>;
            })}
          </ul>
        )}
      </div>
    </Container>
  );
}
