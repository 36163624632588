import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { IPayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISendAccountPayableToFuspContract } from "../../domain/contracts/sendAccountPayableToFuspContract";
import { IFuspAccountPayableIntegrationViewModel } from "../models/fuspAccountPayableIntegrationViewModel";

export class SendAccountPayableToFuspService
  implements ISendAccountPayableToFuspContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async sendAccountPayableToFusp(
    payload: IPayloadEntity,
  ): Promise<IFuspAccountPayableIntegrationViewModel[]> {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      orderableStringField: {
        valueString: "value",
        competencyName: "competencyName",
        projectName: "projectName",
        payUntilString: "payUntil",
        providerName: "providerName",
        paymentMethodDescription: "paymentMethod",
        classificationUspName: "classificationUspName",
        description: "description",
        fuspSendingStatusDescription: "fuspSendingStatus",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsOptions = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/Fusp/SendAccountPayableToFusp?${params}`;

    const response = await this.api.post<
      IFuspAccountPayableIntegrationViewModel[]
    >(url, null, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const hasSomeError = response.some(entry => entry.hasError);

    if (hasSomeError) {
      const error = {
        details: response
          .filter(entry => entry.hasError)
          .map(entry => {
            const providerName = entry.nomeTerceiro;
            const { documentNumber, fieldErrors } = entry;

            return Object.entries(fieldErrors).map(([, errorMessage]) => {
              return `FUSP - ${providerName} - ${documentNumber} - ${errorMessage}`;
            });
          })
          .flat(),
      };

      return Promise.reject(error);
    }

    return response;
  }
}
