import { IApiService } from "../../../../../core/data/services/apiService";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IFetchFuspContractType } from "../../domain/contracts/fetchFuspContractTypeContract";

export class FetchFuspContractTypeService implements IFetchFuspContractType {
  constructor(
    private api: IApiService,
    private getUserLocalService: IGetUserLocalService,
  ) {}

  fetchFuspContractType() {
    const user = this.getUserLocalService.get();
    const url = "Enums/FuspContractTypes";

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
  }
}
