import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { useDateValidator } from "../../../../../../../../core/presentation/hooks/useDateValidator";
import { useDebtImportPage } from "../../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../../FieldWrapper";
import { InputDate } from "../../../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";

interface ContractValidityStartDateFieldProps {
  formIndex: number;
  isRpaAndContract: boolean;
}

export function ContractValidityStartDateField({
  formIndex,
  isRpaAndContract,
}: ContractValidityStartDateFieldProps) {
  const invalidDate = useDateValidator();

  const fieldKey =
    `imports.${formIndex}.fuspContractValidityStartDate` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{
        required: isRpaAndContract,
        validate: { validDate: v => !invalidDate(v || "", "dd/MM/yyyy") },
      }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="form-control">
            <span>Vigência início</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputDate
                {...field}
                onChange={onChange}
                placeholder="00/00/0000"
                id={`txt-contractValidityStartDate-${formIndex}`}
                data-testid={`txt-contractValidityStartDate-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={
                fieldState?.error?.type === "required" && isRpaAndContract
              }
            />
            <InvalidFeedback
              message="Insira uma data válida"
              condition={fieldState?.error?.type === "validDate"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
