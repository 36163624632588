import { useCallback, useMemo } from "react";
import { FaCoins, FaEdit } from "react-icons/fa";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../core/presentation/hooks/useAllowedProfiles";
import { useDocumentFormatter } from "../../../core/presentation/hooks/useDocumentFormatter";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import { ICustomerEntity } from "../../../customer/domain/entities/customerEntity";
import {
  SimpleTableColumn,
  SimpleColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { ICompanyEntity } from "../../domain/entities/companyEntity";
import { CompanyPageModalType } from "../components/CompanyPage";

interface UseCompaniesGridProps {
  handleToggleCompany(id: string): Promise<void>;
  handleOpenModal(info: ICompanyEntity, type: CompanyPageModalType): void;
}

export function useCompaniesGrid({
  handleOpenModal,
  handleToggleCompany,
}: UseCompaniesGridProps) {
  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();
  const documentFormatter = useDocumentFormatter();

  const canManageTaxes = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  const canDeleteCompany = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  const documentBodyTemplate = useCallback(
    ({ cnpj }: ICompanyEntity) => {
      const formattedDocument = documentFormatter(cnpj);

      return <span>{formattedDocument || "Não informado"}</span>;
    },
    [documentFormatter],
  );

  const activeBodyTemplate = useCallback(
    ({ id, active }: ICustomerEntity, { rowIndex }) => {
      return (
        <Toggle
          value={active}
          readonly={!canDeleteCompany}
          inactiveClassName="toggle-muted"
          id={`btn-tgl-status-${rowIndex}`}
          data-testid={`btn-tgl-status-${rowIndex}`}
          onChange={async _active => {
            const text = !_active ? "inativada" : "ativada";
            const result = await dialog.fire({
              icon: "question",
              title: "Você está certo disso?",
              html: `A empresa será ${text}. <br /> Deseja prosseguir?`,
              showCancelButton: true,
              cancelButtonText: "Não",
              preConfirm() {
                dialog.update({
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
                dialog.showLoading();
                return handleToggleCompany(id);
              },
            });

            if (result.isConfirmed) {
              return _active;
            }

            return !_active;
          }}
        />
      );
    },
    [canDeleteCompany, dialog, handleToggleCompany],
  );

  const editBodyTemplate = useCallback(
    (company: ICompanyEntity, { rowIndex }) => {
      const canManageTip = "Gerenciar taxas<br />automáticas de créditos";
      const disabledTip = `Empresas sem projeto não<br />possuem taxas lançadas<br />automaticamente`;
      return (
        <div className="table-actionbar">
          {canManageTaxes ? (
            <button
              type="button"
              id={`btn-manage-tax-${rowIndex}`}
              data-testid={`btn-manage-tax-${rowIndex}`}
              data-tip={company.hasProject ? canManageTip : disabledTip}
              className={`outline-button tool ${
                company.hasProject ? "" : "disabled"
              }`}
              onClick={() => {
                if (!company.hasProject) return;
                handleOpenModal(company, CompanyPageModalType.Taxes);
              }}
            >
              <FaCoins />
            </button>
          ) : null}
          <button
            type="button"
            data-tip="Editar Empresa"
            id={`btn-edit-company-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-edit-company-${rowIndex}`}
            onClick={() => {
              handleOpenModal(company, CompanyPageModalType.Crud);
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [canManageTaxes, handleOpenModal],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        selectionMode: "multiple",
        style: {
          width: "1%",
          paddingLeft: "1rem",
        },
      },
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        searchable: false,
        orderable: false,
        style: {
          width: "19.8%",
        },
      },
      {
        field: "assumedName",
        header: "Nome fantasia",
        searchable: true,
        orderable: true,
        style: {
          width: "19.8%",
        },
      },
      {
        field: "municipalActivityClassificationCode",
        hidden: true,
      },
      {
        field: "cnaeClassificationCode",
        hidden: true,
      },
      {
        field: "cnpj",
        header: "CNPJ",
        searchable: true,
        orderable: true,
        bodyTemplate: documentBodyTemplate,
        style: {
          width: "19.8%",
        },
      },
      {
        field: "corporationName",
        hidden: true,
      },
      {
        header: "",
        bodyTemplate: editBodyTemplate,
        style: {
          width: "19.8%",
        },
      },
      {
        field: "issAliquot",
        hidden: true,
      },
      {
        field: "irAliquot",
        hidden: true,
      },
      {
        field: "csllAliquot",
        hidden: true,
      },
      {
        field: "pisAliquot",
        hidden: true,
      },
      {
        field: "cofinsAliquot",
        hidden: true,
      },
      {
        field: "serviceSupplyStateName",
        hidden: true,
      },
      {
        field: "serviceSupplyCityName",
        hidden: true,
      },
      {
        field: "invoiceServiceDescription",
        hidden: true,
      },
      {
        field: "active",
        header: "Ativo",
        orderable: false,
        searchable: false,
        bodyTemplate: activeBodyTemplate,
        style: {
          width: "19.8%",
        },
      },
      {
        field: "activeDescription",
        hidden: true,
      },
      {
        hidden: true,
        field: "hasProject",
      },
      {
        hidden: true,
        field: "hasProjectDescription",
      },
      {
        hidden: true,
        field: "hasTaxes",
      },
      {
        field: "lockReleasesUntil",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, documentBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
