import { useCallback, useMemo } from "react";
import { FaEdit, FaFileInvoiceDollar } from "react-icons/fa";

import { Toggle } from "../../../core/presentation/components/Toggle";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IPecegePayAccountReceivableFeeEntity } from "../../domain/entities/pecegePayAccountReceivableFeeEntity";
import { PecegePayAccountReceivableFeesPageModalType } from "../components/PecegePayAccountReceivablePage";

interface UsePecegePayReceivableFeesGridParams {
  handleTooglePecegePayReceivableFee(
    payload: IPecegePayAccountReceivableFeeEntity,
  ): Promise<boolean>;
  handleOpenModal(
    info: IPecegePayAccountReceivableFeeEntity,
    type: PecegePayAccountReceivableFeesPageModalType,
  ): void;
}

export function usePecegePayReceivableFeesGrid(
  params: UsePecegePayReceivableFeesGridParams,
) {
  const {
    handleOpenModal,
    handleTooglePecegePayReceivableFee:
      handleTooglePecegePayAccountReceivableFee,
  } = params;

  const activeBodyTemplate = useCallback(
    (data: IPecegePayAccountReceivableFeeEntity, { rowIndex }) => {
      const props: Record<string, string> = {};

      return (
        <div {...props}>
          <Toggle
            value={data.active}
            inactiveClassName="toggle-muted"
            id={`btn-tgl-status-${rowIndex}`}
            data-testid={`btn-tgl-status-${rowIndex}`}
            onChange={() => handleTooglePecegePayAccountReceivableFee(data)}
          />
        </div>
      );
    },
    [handleTooglePecegePayAccountReceivableFee],
  );

  const editBodyTemplate = useCallback(
    (data: IPecegePayAccountReceivableFeeEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            type="button"
            data-tip="Gerenciar tarifa"
            id={`btn-manage-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-manage-${rowIndex}`}
            onClick={() => {
              handleOpenModal(
                data,
                PecegePayAccountReceivableFeesPageModalType.Manage,
              );
            }}
          >
            <FaFileInvoiceDollar />
          </button>
          <button
            type="button"
            data-tip="Editar Tarifa"
            id={`btn-edit-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              handleOpenModal(
                data,
                PecegePayAccountReceivableFeesPageModalType.Crud,
              );
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [handleOpenModal],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        searchable: false,
        orderable: false,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "companyName",
        header: "Empresa",
        searchable: true,
        orderable: true,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "providerName",
        header: "Fornecedor",
        searchable: true,
        orderable: true,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "walletName",
        header: "Carteira",
        searchable: true,
        orderable: true,
        style: {
          width: "16.5%",
        },
      },
      {
        header: "",
        bodyTemplate: editBodyTemplate,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "active",
        header: "Ativo",
        orderable: false,
        searchable: false,
        bodyTemplate: activeBodyTemplate,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "walletId",
        hidden: true,
      },
      {
        field: "classificationAccountName",
        hidden: true,
      },
      {
        field: "classificationAssessmentName",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
