import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../advTable/domain/entities/advTableColumn";
import { EEnumListBoxOptions } from "../../../../advTable/presentation/components/ColumnFilterEnum";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EDocumentStatus,
  EPaymentAccountReportStatus,
  EPaymentAccountReportType,
  EPaymentMethod,
  EReportOrigin,
  EReturnStatus,
  IPaymentAccountReportEntity,
  paymentAccountReportDocumentStatusLang,
  paymentAccountReportOriginLang,
  paymentAccountReportPaymentMethodLang,
  paymentAccountReportStatusLang,
  paymentAccountReportTypeLang,
  returnStatusLang,
} from "../../domain/entities/paymentAccountReportEntity";
import { MakePaymentAccountReport } from "../../main/makePaymentAccountReport";
import { BadgeType } from "../components/BadgeType/styles";

export enum EEditModalMode {
  edit,
  readonly,
}

interface IUseCostCenterReportGridParams {
  usePaymentAccountReport: MakePaymentAccountReport;
}

export function usePaymentAccountReportGrid({
  usePaymentAccountReport,
}: IUseCostCenterReportGridParams) {
  const originMap: Record<EReportOrigin, string> = useMemo(
    () => ({
      [EReportOrigin.OpeningBalance]: "",
      [EReportOrigin.accountPayable]: "(rateio)",
      [EReportOrigin.accountReceivable]: "(parcela)",
      [EReportOrigin.transaction]: "",
    }),
    [],
  );

  const {
    searchUser,
    searchCostCenter,
    searchClassificationAccount,
    searchClassificationUsp,
    searchCompany,
    searchCompetency,
    searchPaymentAccount,
    searchProject,
    searchCustomerProvider,
    searchCustomerProviderByCorporationName,
  } = usePaymentAccountReport;
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Origem",
        field: "originDescription",
        searchable: true,
        orderable: true,
        sortField: "origin",
        searchField: "origin",
        className: "text-truncate",
        columnType: EColumnType.enum,
        enumObject: EReportOrigin,
        enumLang: paymentAccountReportOriginLang,
        width: "130px",
      },
      {
        header: "Tipo",
        field: "typeDescription",
        searchable: false,
        orderable: true,
        sortField: "type",
        searchField: "type",
        columnType: EColumnType.enum,
        enumObject: EPaymentAccountReportType,
        enumLang: paymentAccountReportTypeLang,
        className: "text-truncate",
        width: "152px",
        bodyTemplate(rowData: IPaymentAccountReportEntity) {
          const { type, typeDescription } = rowData;
          return (
            <BadgeType title={typeDescription} type={type}>
              {typeDescription}
            </BadgeType>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | EPaymentAccountReportType;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const type = value as EPaymentAccountReportType;
          return (
            <BadgeType title={label} type={type}>
              {label}
            </BadgeType>
          );
        },
      },
      {
        header: "Valor do lançamento",
        field: "totalValueString",
        searchField: "totalValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "215px",
        className: "text-truncate",
      },
      {
        header: "Valor da parcela",
        field: "parcelValueString",
        sortField: "parcelValue",
        searchField: "parcelValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "198px",
        className: "text-truncate",
        bodyTemplate(rowData: IPaymentAccountReportEntity) {
          const { parcelValueString, origin } = rowData;
          const valueDescription = originMap[origin];

          return (
            <div
              className="cell-truncate"
              title={`${parcelValueString} ${valueDescription}`}
            >
              {parcelValueString}{" "}
              {valueDescription && (
                <small className="text-muted">{valueDescription}</small>
              )}
            </div>
          );
        },
      },
      {
        header: "Centro de custo",
        field: "costCenterName",
        searchField: "costCenterId",
        className: "text-truncate",
        width: "180px",
        orderable: true,
        searchable: true,
        columnType: EColumnType.relationship,
        async getList(search = "", fetchMinLength = 10) {
          return searchCostCenter({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Nº da parcela",
        orderable: false,
        field: "parcelNumber",
        width: "168px",
        columnType: EColumnType.integer,
        className: "text-truncate",
        bodyTemplate(rowData: IPaymentAccountReportEntity) {
          const { parcelNumber, numberOfParcels } = rowData;
          const formatedParcelNumber = `${parcelNumber}/${numberOfParcels}`;

          return (
            <div title={formatedParcelNumber} className="cell-truncate">
              {formatedParcelNumber}
            </div>
          );
        },
      },
      { field: "numberOfParcels", hidden: true },
      {
        header: "Cliente / Fornecedor",
        field: "customerProviderName",
        searchField: "customerProviderId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchCustomerProvider({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Cliente / Fornecedor (razão social)",
        field: "customerProviderCorporationName",
        searchField: "customerProviderId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchCustomerProviderByCorporationName({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
        headerTemplate() {
          return (
            <>
              Cliente / Fornecedor <br />
              <small>(razão social)</small>
            </>
          );
        },
      },
      {
        header: "Cidade cliente / fornecedor",
        orderable: false,
        field: "customerProviderCityState",
        className: "text-truncate",
        width: "250px",
      },
      {
        header: "Nº documento",
        orderable: false,
        field: "documentNumber",
        className: "text-truncate",
        width: "168px",
      },
      {
        header: "Serial",
        orderable: false,
        field: "serial",
        className: "text-truncate",
        width: "150px",
      },
      {
        header: "TID",
        field: "creditCardLimitTransactionId",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Observação",
        orderable: false,
        field: "observation",
        className: "text-truncate",
        width: "204px",
        bodyTemplate(rowData: IPaymentAccountReportEntity) {
          const { observation = "" } = rowData;
          return <span title={observation}>{observation}</span>;
        },
      },
      {
        header: "Descrição",
        orderable: false,
        field: "description",
        className: "text-truncate",
        width: "140px",
      },
      {
        header: "Lançado por",
        field: "userNameCreated",
        searchable: true,
        orderable: true,
        searchField: "userCreated",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Status",
        field: "statusDescription",
        searchable: true,
        orderable: true,
        sortField: "status",
        searchField: "status",
        columnType: EColumnType.enum,
        enumObject: EPaymentAccountReportStatus,
        enumLang: paymentAccountReportStatusLang,
        width: "124px",
        className: "text-truncate",
      },
      {
        header: "Status devolução",
        field: "returnStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "returnStatus",
        searchField: "returnStatus",
        columnType: EColumnType.enum,
        enumObject: EReturnStatus,
        enumLang: returnStatusLang,
        width: "210px",
        className: "text-truncate",
      },
      {
        header: "Status da documentação",
        field: "documentStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "documentStatus",
        searchField: "documentStatus",
        columnType: EColumnType.enum,
        enumObject: EDocumentStatus,
        enumLang: paymentAccountReportDocumentStatusLang,
        width: "230px",
        className: "text-truncate",
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EPaymentMethod,
        enumLang: paymentAccountReportPaymentMethodLang,
        className: "text-truncate",
        width: "216px",
      },
      {
        header: "Data da operação",
        field: "operationDateString",
        searchable: true,
        orderable: true,
        searchField: "operationDate",
        sortField: "operationDate",
        columnType: EColumnType.date,
        width: "186px",
        className: "text-truncate",
      },
      {
        header: "Data de emissão",
        field: "issueDateString",
        searchable: true,
        orderable: true,
        searchField: "issueDate",
        sortField: "issueDate",
        columnType: EColumnType.date,
        width: "176px",
        className: "text-truncate",
      },
      {
        header: "Data de lançamento",
        field: "dateCreatedString",
        searchable: true,
        orderable: true,
        searchField: "dateCreated",
        sortField: "dateCreated",
        columnType: EColumnType.date,
        width: "204px",
        className: "text-truncate",
      },
      {
        header: "Empresa",
        field: "companyName",
        orderable: true,
        searchable: true,
        searchField: "companyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "") {
          return searchCompany({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: 50,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Classificação contábil",
        field: "classificationAccountName",
        orderable: true,
        searchable: true,
        searchField: "classificationAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "212px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAccount({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Classificação USP",
        field: "classificationUspName",
        orderable: true,
        searchable: true,
        searchField: "classificationUspId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationUsp({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Competência",
        field: "competencyName",
        orderable: true,
        searchable: true,
        searchField: "competencyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "158px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompetency({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Conta de pagamento",
        field: "paymentAccountName",
        orderable: true,
        searchable: true,
        searchField: "paymentAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 10) {
          return searchPaymentAccount({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Projeto",
        field: "projectName",
        orderable: true,
        searchable: true,
        searchField: "projectId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "", fetchMinLength = 10) {
          return searchProject({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data de modificação",
        field: "dateModifiedString",
        searchable: true,
        orderable: true,
        searchField: "dateModified",
        sortField: "dateModified",
        columnType: EColumnType.date,
        width: "218px",
        className: "text-truncate",
      },
      {
        header: "Atualizado por",
        field: "userNameModified",
        searchable: true,
        orderable: true,
        searchField: "userModified",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "186px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      { field: "originBarcode", hidden: true },
      { field: "totalValue", hidden: true },
      { field: "originId", hidden: true },
      { field: "origin", hidden: true },
      { field: "type", hidden: true },
    ].map(colParams => new SimpleColumn(colParams));
  }, [
    id,
    originMap,
    searchClassificationAccount,
    searchClassificationUsp,
    searchCompany,
    searchCompetency,
    searchCostCenter,
    searchCustomerProvider,
    searchCustomerProviderByCorporationName,
    searchPaymentAccount,
    searchProject,
    searchUser,
  ]);

  return {
    columns,
  };
}
