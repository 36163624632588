import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISendAccountPayableToFuspByIdsContract } from "../../domain/contracts/sendAccountPayableToFuspByIdsContract";
import { IFuspAccountPayableIntegrationEntity } from "../../domain/entities/fuspAccountPayableIntegrationEntity";
import { IFuspAccountPayableIntegrationViewModel } from "../models/fuspAccountPayableIntegrationViewModel";

export class SendAccountPayableToFuspByIdsService
  implements ISendAccountPayableToFuspByIdsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async sendAccountPayableToFuspByIds(
    fuspAccountPayableIntegrationEntities: IFuspAccountPayableIntegrationEntity[],
  ): Promise<IFuspAccountPayableIntegrationViewModel[]> {
    const ids = fuspAccountPayableIntegrationEntities.map(entry => entry.id);

    const payload = {
      accountPayableIds: ids,
    };

    const userEntity = this.getUserLocalService.get();
    const url = `/Fusp/SendAccountPayableToFuspByIds`;

    const response = await this.api.post<
      IFuspAccountPayableIntegrationViewModel[]
    >(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const hasSomeError = response.some(entry => entry.hasError);

    if (hasSomeError) {
      const error = {
        details: response
          .filter(entry => entry.hasError)
          .map(entry => {
            const providerName = entry.nomeTerceiro;
            const { documentNumber, fieldErrors } = entry;

            return Object.entries(fieldErrors).map(([, errorMessage]) => {
              return `FUSP - ${providerName} - ${documentNumber} - ${errorMessage}`;
            });
          })
          .flat(),
      };

      return Promise.reject(error);
    }

    return response;
  }
}
