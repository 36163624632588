import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import {
  IListMacroCategoriesContract,
  IListMacroCategoriesContractParams,
} from "../../domain/contracts/listMacroCategoriesContract";
import { IMacroCategoryEntity } from "../../domain/entities/macroCategoryEntity";

export class ListMacroCategoriesService
  implements IListMacroCategoriesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
    private setServerSideService: ISetServerSideService,
  ) {}

  async listMacroCategories({
    payload,
    showActivesOnly,
  }: IListMacroCategoriesContractParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "/MacroBoxes/Grid";

    if (showActivesOnly) {
      const findActivePayloadColumn = payload.columns.find(
        item => item.data === "active",
      );

      if (findActivePayloadColumn) {
        findActivePayloadColumn.searchable = true;
        findActivePayloadColumn.search = {
          regex: false,
          value: "true",
        };
      }
    }

    const serverSideId = await this.setServerSideService.setServerSide(payload);

    const params = new URLSearchParams({
      body: serverSideId,
    });

    return this.api.get<IServerSideResponseModel<IMacroCategoryEntity[]>>(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
