import { IEntity } from "../../../../../core/domain/entities/entity";
import { ProviderDocumentType } from "../../../../../provider/domain/entities/providerEntity";

export enum EFuspSendingStatus {
  PendingSending = 0,
  Sent = 1,
  SendingFailure = 2,
}

export const fuspSendingStatusLang: Record<
  keyof typeof EFuspSendingStatus,
  string
> = {
  PendingSending: "Pendente",
  SendingFailure: "Falha de envio",
  Sent: "Enviado",
};

export enum EAccountPayableDocumentStatus {
  Complete = 1,
  Incomplete = 2,
}

export const accountPayableDocumentStatusLang: Record<
  keyof typeof EAccountPayableDocumentStatus,
  string
> = {
  Complete: "Completo",
  Incomplete: "Incompleto",
};

export enum EAccountPayableStatus {
  Open = 1,
  Paid = 2,
  Canceled = 3,
}

export const accountPayableStatusLang: Record<
  keyof typeof EAccountPayableStatus,
  string
> = {
  Open: "Aberto",
  Paid: "Pago",
  Canceled: "Cancelado",
};

export enum EReturnStatus {
  NotReturned = 1,
  PartiallyReturned = 2,
  FullyReturned = 3,
}

export const returnStatusLang: Record<keyof typeof EReturnStatus, string> = {
  NotReturned: "Não devolvido",
  PartiallyReturned: "Parcialmente devolvido ",
  FullyReturned: "Totalmente devolvido",
};

export enum EAccountPayablePaymentMethod {
  Billet = 1,
  CreditCard = 2,
  CurrentAccountTransfer = 3,
  Cash = 4,
  Check = 5,
  DirectDebit = 6,
  Pix = 7,
  Forecast = 8,
}

export enum EFuspContractType {
  NotRpa = 0,
  Rpa = 1,
  RpaAndContract = 2,
}

export const accountPayablePaymentMethodLang: Record<
  keyof typeof EAccountPayablePaymentMethod,
  string
> = {
  Billet: "Boleto",
  CreditCard: "Cartão de Crédito",
  CurrentAccountTransfer: "Transferência em Conta Corrente",
  Cash: "Dinheiro",
  Check: "Cheque",
  DirectDebit: "Débito em Conta Corrente",
  Pix: "Pix",
  Forecast: "Previsão",
};

export interface IAccountPayableListItemEntity extends IEntity {
  documentNumber: string;

  // valor
  value: number;
  valueString: string;

  // data emissão (timestamp)
  issueDate: string;
  // data emissão (formatada server-side)
  issueDateString: string;

  // pagar ate (timestamp)
  payUntil: string;
  // pagar ate (formatada server-side)
  payUntilString: string;

  // Data de baixa (timestamp)
  terminationDate: string;
  // Data de baixa (formatada server-side)
  terminationDateString: string;

  // Nome do usuário que baixou o lançamento
  terminationUserName: string;
  // Id do usuário que baixou o lançamento
  terminationUserId: string;

  documentStatus: EAccountPayableDocumentStatus | null;
  documentStatusDescription: string;

  status: EAccountPayableStatus;
  statusDescription: string;

  returnStatus: EReturnStatus;
  returnStatusDescription: string;

  paymentMethod: EAccountPayablePaymentMethod | null;
  paymentMethodDescription: string;

  description: string;
  observation: string;

  // data de exclusao (timestamp)
  deleteDate: string;
  // data de exclusao (formatada server-side)
  deleteDateString: string;

  deleteUserId: string;
  deleteUserName: string;

  deleteReason: string;

  companyId: string;
  companyName: string;

  companyGroupId: string;
  companyGroupName: string;

  competencyId: string;
  competencyName: string;

  projectId: string;
  projectName: string;

  providerId: string;
  providerName: string;

  classificationUspId: string;
  classificationUspName: string;

  classificationAccountId: string;
  classificationAccountName: string;

  paymentAccountId: string;
  paymentAccountName: string;

  // Data de ret. baixa (timestamp)
  chargebackDate: string;
  // Data de ret. baixa (formatada server-side)
  chargebackDateString: string;

  // usuario que retirou a baixa
  chargebackUserId: string;
  chargebackUserName: string;

  // Data de lancamento (timestamp)
  dateCreated: string;
  // Data de lancamento (formatada server-side)
  dateCreatedString: string;

  // Data de modificação (timestamp)
  dateModified: string;
  // Data de modificação (formatada server-side)
  dateModifiedString: string;

  userModified: string;
  userNameModified: string;

  paymentRequestId: string | null;

  // indica se essa conta se originou de uma taxa ou nao
  isTax: boolean;

  // indica se essa conta esta associada a um fornecedor que retem impostos
  providerRetainFee: boolean;

  // data devolucao (timestamp)
  returnDate: string;
  // data devolucao (formatada server-side)
  returnDateString: string;

  returnUserName: string;
  returnUserId: string;

  requestedUserId: string;
  requestedUserName: string;

  approvedUserId: string;
  approvedUserName: string;

  // data aprovacao (timestamp)
  approvedDate: string;
  // data aprovacao (formatada server-side)
  approvedDateString: string;

  /**
   * Indica se a conta é originada a partir de retenção de um imposto.
   */
  isFeeRetention: boolean;

  // Data de remessa
  remittanceDate: string;
  // Id do usuário que fez a remessa
  remittanceUserId: string;
  // Nome do usuário que fez a remessa
  remittanceUserName: string;

  // campos fusp
  fuspPurchaseOrderId: string;
  fuspReimbursement: boolean;

  fuspSendingStatus: EFuspSendingStatus | null;
  fuspSendingStatusDescription: string;

  // outros campos fusp
  fuspSendingUserId: string | null;
  fuspSendingUserName: string | null;
  fuspSendingDate: string | null;
  fuspSendingFailureReason: string | null;

  providerDocumentType?: ProviderDocumentType;

  pecegePayFeeId?: string | null;
}

export class AccountPayableListItemEntity
  implements IAccountPayableListItemEntity
{
  documentNumber = "";
  value = 0;
  valueString = "";
  issueDate = "";
  issueDateString = "";
  payUntil = "";
  payUntilString = "";
  terminationDate = "";
  terminationDateString = "";
  terminationUserName = "";
  terminationUserId = "";
  documentStatus = null;
  documentStatusDescription = "";
  status = EAccountPayableStatus.Open;
  statusDescription = "";
  returnStatus = EReturnStatus.NotReturned;
  returnStatusDescription = "";
  paymentMethod = null;
  paymentMethodDescription = "";
  description = "";
  observation = "";
  deleteDate = "";
  deleteDateString = "";
  deleteUserId = "";
  deleteUserName = "";
  deleteReason = "";
  companyId = "";
  companyName = "";
  companyGroupId = "";
  companyGroupName = "";
  competencyId = "";
  competencyName = "";
  projectId = "";
  projectName = "";
  providerId = "";
  providerName = "";
  classificationUspId = "";
  classificationUspName = "";
  classificationAccountId = "";
  classificationAccountName = "";
  paymentAccountId = "";
  paymentAccountName = "";
  chargebackDate = "";
  chargebackDateString = "";
  chargebackUserName = "";
  chargebackUserId = "";
  dateCreated = "";
  dateCreatedString = "";
  dateModified = "";
  dateModifiedString = "";
  userModified = "";
  userNameModified = "";
  paymentRequestId = null;
  isTax = false;
  providerRetainFee = false;
  returnDate = "";
  returnDateString = "";
  returnUserName = "";
  returnUserId = "";
  requestedUserId = "";
  requestedUserName = "";
  approvedUserId = "";
  approvedUserName = "";
  approvedDate = "";
  approvedDateString = "";
  isFeeRetention = false;
  id = "";
  active = true;
  remittanceDate = "";
  remittanceUserId = "";
  remittanceUserName = "";

  // campos fusp
  fuspPurchaseOrderId = "";
  fuspReimbursement = false;

  fuspSendingStatus = null;
  fuspSendingStatusDescription = "";

  // outros campos fusp
  fuspSendingUserId = null;
  fuspSendingUserName = null;
  fuspSendingDate = null;
  fuspSendingFailureReason = null;

  constructor(init?: Partial<IAccountPayableListItemEntity>) {
    Object.assign(this, init);
  }
}
