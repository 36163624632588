import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { useDebtImportPage } from "../../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../../FieldWrapper";
import { IFormComponentProps } from "../../../../../domain/entities/debtImportTypes";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";
import { EFuspContractType } from "../../../../../domain/entities/debtImportEnums";

export function ContractTypeField({ formIndex }: IFormComponentProps) {
  const fieldKey = `imports.${formIndex}.fuspContractType` as const;

  const { handleInputClassName, state } = useDebtImportPage();

  const { typeOfContractsEnums } = state;

  return (
    <Controller
      name={fieldKey}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
          const inputValue = event.target.value;

          const enumValue =
            typeOfContractsEnums?.find(e => e.key === Number(inputValue)) ||
            null;

          field.onBlur();
          field.onChange(enumValue);
        };

        return (
          <label className="form-control">
            <span>Solicitação de Pagamento</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <select
                {...field}
                onChange={onChange}
                value={field.value?.key || EFuspContractType.NotRpa}
                id={`slc-fuspContractType-${formIndex}`}
                data-testid={`slc-fuspContractType-${formIndex}`}
              >
                <option value="" disabled>
                  Selecione um tipo
                </option>

                {typeOfContractsEnums?.map(enumObject => {
                  return (
                    <option
                      value={enumObject.key}
                      key={`${enumObject.key}-${enumObject.value}`}
                    >
                      {enumObject.value}
                    </option>
                  );
                })}
              </select>
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
          </label>
        );
      }}
    />
  );
}
