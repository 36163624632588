import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { IFormComponentProps } from "../../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../../FieldWrapper";
import { Checkbox } from "../../../../../../../../core/presentation/components/Checkbox";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";

export function ReimbursementField({ formIndex }: IFormComponentProps) {
  const fieldKey = `imports.${formIndex}.fuspReimbursement` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      render={({ field, fieldState }) => {
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(e);
        };

        return (
          <label className="form-control">
            <span>&nbsp;</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <Checkbox
                name={field.name}
                checked={field.value ?? false}
                onChange={handleChange}
                id="chk-fusp-reimbursement-id"
                label="Reembolso"
              />
            </FieldWrapper>
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
