import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveImportDataContract } from "../../domain/contracts/saveImportDataContract";
import { IDebtImportAssessmentEntity } from "../../domain/entities/debtImportAssessmentEntity";
import {
  DebtImportEntity,
  IDebtImportEntity,
} from "../../domain/entities/debtImportEntity";
import {
  IDebtImportPayloadModel,
  IDebtImportResponseModel,
} from "../models/debtImportModel";
import { MainDebtImportService } from "./mainDebtImportService";

export class SaveImportDataService
  extends MainDebtImportService
  implements ISaveImportDataContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async saveImportData(importData: IDebtImportEntity[]) {
    const userEntity = this.getUserLocalService.get();
    const url = "/AccountPayableImportations/Save";

    const payload = importData.map<Partial<IDebtImportPayloadModel>>(
      accountData => {
        return {
          accountAlreadyPaid: accountData.accountAlreadyPaid,
          accountAlreadyExistsForAnotherUser:
            accountData.accountAlreadyExistsForAnotherUser,
          assessments: accountData.assessments.map(assessment => ({
            classificationAssessmentId:
              (assessment.classificationAssessment?.rawValue as string) || "",
            classificationAssessmentName:
              (assessment.classificationAssessment?.label as string) || "",
            costCenterId: (assessment.costCenter?.rawValue as string) || "",
            costCenterName: (assessment.costCenter?.label as string) || "",
            fieldErrors: assessment.fieldErrors,
            hasError: assessment.hasError,
            observation: assessment.observation,
            percentage: assessment.percentage,
            value: assessment.value,
            valueAssessmentLeft: assessment.valueAssessmentLeft,
          })),
          barcode: accountData.barcode || "",
          classificationAccountId:
            (accountData.classificationAccount?.rawValue as string) || "",
          classificationAccountName: accountData.classificationAccount?.label,
          classificationUspId:
            (accountData.classificationUsp?.rawValue as string) || "",
          classificationUspName: accountData.classificationUsp?.label,
          companyId: (accountData.company?.rawValue as string) || "",
          companyName: accountData.company?.label,
          competencyId: (accountData.competency?.rawValue as string) || "",
          competencyName: accountData.competency?.label,
          description: accountData.description,
          documentNumber: accountData.documentNumber,
          documentStatus: accountData.documentStatus?.key,
          getCompanyHasProject: accountData.getCompanyHasProject,
          getCompetencyIsUsp: accountData.getCompetencyIsUsp,
          id: accountData.id,
          issueDate: this.dateFormatter(accountData.issueDate),
          observation: accountData.observation,
          paymentAccountId:
            (accountData.paymentAccount?.rawValue as string) || "",
          paymentAccountName: accountData.paymentAccount?.label,
          paymentMethod: accountData.paymentMethod?.key,
          payUntil: this.dateFormatter(accountData.payUntil),
          projectId: (accountData.project?.rawValue as string) || "",
          projectName: accountData.project?.label,
          providerId: (accountData.provider?.rawValue as string) || "",
          providerName: accountData.provider?.label || "",
          storageFiles: accountData.storageFiles.map(f => ({
            ...f,
            file: f.file || null,
            barcode: f.barcode || "",
            storageFileId: f.storageFileId || "",
          })),
          terminationDate: this.dateFormatter(accountData.terminationDate),
          value: accountData.value,
          fuspPurchaseOrderId:
            accountData.fuspPurchaseOrderId?.toString() || "",
          fuspContractType: accountData.fuspContractType?.key,
          fuspContractTypeDescription:
            accountData.fuspContractType?.value || "",
          fuspContractValidityStartDate:
            accountData.fuspContractValidityStartDate
              ? this.dateFormatter(accountData.fuspContractValidityStartDate)
              : "",
          fuspContractValidityEndDate: accountData.fuspContractValidityEndDate
            ? this.dateFormatter(accountData.fuspContractValidityEndDate)
            : "",
          fuspReimbursement: accountData.fuspReimbursement || false,
          correspondingProviderName: accountData.correspondingProviderName,
        };
      },
    );

    const response = await this.api.post<IDebtImportResponseModel[]>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const dataResponse = response.map(responseData => {
      const datePayUntil = new Date(responseData.payUntil);
      const dateIssueDate = new Date(responseData.issueDate);
      const dateTerminationDate = responseData?.terminationDate
        ? new Date(responseData.terminationDate)
        : "";

      const dateFuspContractValidityStartDate =
        responseData.fuspContractValidityStartDate
          ? new Date(responseData.fuspContractValidityStartDate)
          : "";

      const dateFuspContractValidityEndDate =
        responseData.fuspContractValidityEndDate
          ? new Date(responseData.fuspContractValidityEndDate)
          : "";

      const provider = this.buildTypeaheadObject(responseData, "provider");
      const company = this.buildTypeaheadObject(responseData, "company");
      const competency = this.buildTypeaheadObject(responseData, "competency");

      if (company) {
        company.metadata = {
          hasProject: responseData.getCompanyHasProject,
        };
      }

      if (competency) {
        competency.metadata = {
          isUsp: responseData.getCompetencyIsUsp,
        };
      }

      if (provider && typeof responseData.providerDocumentType === "number") {
        provider.metadata = {
          documentType: responseData.providerDocumentType,
        };
      }

      return new DebtImportEntity({
        id: responseData.id,
        accountAlreadyExists: responseData?.accountAlreadyExists || false,
        accountAlreadyPaid: responseData?.accountAlreadyPaid || false,
        assessments:
          responseData?.assessments?.map<IDebtImportAssessmentEntity>(
            assessment => ({
              hasError: assessment.hasError,
              fieldErrors: assessment.fieldErrors,
              observation: assessment.observation || "",
              value: new BigNumber(assessment.value).toJSON(),
              valueAssessmentLeft: assessment.valueAssessmentLeft,
              percentage: new BigNumber(assessment.percentage).toJSON(),
              costCenter: this.buildTypeaheadObject(assessment, "costCenter"),
              classificationAssessment: this.buildTypeaheadObject(
                assessment,
                "classificationAssessment",
              ),
            }),
          ) || [],
        barcode: responseData?.barcode || "",
        classificationAccount: this.buildTypeaheadObject(
          responseData,
          "classificationAccount",
        ),
        classificationUsp: this.buildTypeaheadObject(
          responseData,
          "classificationUsp",
        ),
        company,
        competency,
        description: responseData?.description?.toUpperCase() || "",
        documentNumber: responseData?.documentNumber?.toUpperCase() || "",
        documentStatus: {
          key: responseData?.documentStatus ?? "",
          value: responseData.documentStatusDescription,
        },
        getCompanyHasProject: responseData.getCompanyHasProject,
        getCompetencyIsUsp: responseData.getCompetencyIsUsp,
        hasAssessmentError: responseData.hasAssessmentError,
        hasError: responseData.hasError,
        issueDate: format(dateIssueDate, "dd/MM/yyyy"),
        observation: responseData?.observation?.toUpperCase() || "",
        payUntil: format(datePayUntil, "dd/MM/yyyy"),
        paymentAccount: this.buildTypeaheadObject(
          responseData,
          "paymentAccount",
        ),
        paymentMethod: {
          key: responseData?.paymentMethod ?? "",
          value: responseData.paymentMethodDescription,
        },
        project: this.buildTypeaheadObject(responseData, "project"),
        provider,
        storageFiles: responseData?.storageFiles || [],
        terminationDate: dateTerminationDate
          ? format(dateTerminationDate, "dd/MM/yyyy")
          : "",
        value: responseData.value,
        valueAssessmentLeft: responseData.valueAssessmentLeft,
        fieldErrors: responseData.fieldErrors,
        fuspPurchaseOrderId: responseData.fuspPurchaseOrderId?.toString() || "",
        fuspContractType: {
          key: responseData?.fuspContractType ?? "",
          value: responseData.fuspContractTypeDescription,
        },
        fuspContractValidityStartDate: dateFuspContractValidityStartDate
          ? format(dateFuspContractValidityStartDate, "dd/MM/yyyy")
          : "",
        fuspContractValidityEndDate: dateFuspContractValidityEndDate
          ? format(dateFuspContractValidityEndDate, "dd/MM/yyyy")
          : "",
        fuspReimbursement: responseData.fuspReimbursement || false,
        correspondingProviderName:
          responseData?.correspondingProviderName || "",
      });
    });

    return dataResponse;
  }
}
