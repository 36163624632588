import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { useDateValidator } from "../../../../../../../../core/presentation/hooks/useDateValidator";
import { useSolicitImportPage } from "../../../../hooks/useSolicitImportPage";
import { FieldWrapper } from "../../../FieldWrapper";
import { InputDate } from "../../../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";

interface ContractValidityEndDateFieldProps {
  formIndex: number;
  isRpaAndContract: boolean;
}

export function ContractValidityEndDateField({
  formIndex,
  isRpaAndContract,
}: ContractValidityEndDateFieldProps) {
  const invalidDate = useDateValidator();

  const fieldKey = `imports.${formIndex}.fuspContractValidityEndDate` as const;

  const { handleInputClassName } = useSolicitImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{
        required: isRpaAndContract,
        validate: { validDate: v => !invalidDate(v || "", "dd/MM/yyyy") },
      }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="form-control">
            <span>Vigência fim</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputDate
                {...field}
                onChange={onChange}
                placeholder="00/00/0000"
                id={`txt-contractValidityEndDate-${formIndex}`}
                data-testid={`txt-contractValidityEndDate-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={
                fieldState?.error?.type === "required" && isRpaAndContract
              }
            />
            <InvalidFeedback
              message="Insira uma data válida"
              condition={fieldState?.error?.type === "validDate"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
