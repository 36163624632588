import { useCallback, useMemo, useState } from "react";
import { FieldError, FieldErrors, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { NOT_FOUND_OPTION_ID } from "../../../../../../core/presentation/components/SoulTypeahead";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { MakeProvider } from "../../../../../../provider/main/makeProvider";
import { ProviderFormModal } from "../../../../../../provider/presentation/components/ProviderFormModal";
import { IAccountPayableFormEntity } from "../../../domain/entities/accountPayableFormEntity";
import { MakeAccountsPayableForm } from "../../../main/makeAccountPayableForm";
import { EFormMode } from "../AccountsPayableFormPage";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";
import {
  EAccountSubmitMode,
  AccountFormSplitButton,
} from "../../../../../../core/presentation/components/AccountFormSplitButton";

interface ISectionFooterState {
  isUploadModalOpen: boolean;
  isProviderModalOpen: boolean;
  loaded: number;
  total: number;
}

interface ISectionFooterProps {
  mode: EFormMode;
  readonly: boolean;
  useProvider: MakeProvider;
  useAccountsPayableForm: MakeAccountsPayableForm;
}

interface ISubmitHandlerParams {
  submitMode: EAccountSubmitMode;
  newAccountPayableId: string;
}

export function SectionFooter({
  mode,
  readonly,
  useProvider,
  useAccountsPayableForm,
}: ISectionFooterProps) {
  const [state, setState] = useState<ISectionFooterState>({
    total: 0,
    loaded: 0,
    isUploadModalOpen: false,
    isProviderModalOpen: false,
  });

  const { uploadAttachments, updateAccountPayable, postNewAccountPayable } =
    useAccountsPayableForm;

  const form = useFormContext<IAccountPayableFormEntity>();

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const dialog = useSoulDialog();
  const navigate = useNavigate();

  const provider = watch("provider");
  const providerId = provider?.rawValue as string | undefined;

  const memoizedProviderId = useMemo(() => {
    if (providerId === NOT_FOUND_OPTION_ID) {
      return "";
    }

    return providerId;
  }, [providerId]);

  const onUploadProgress = (event: ProgressEvent) => {
    setState(prevState => ({
      ...prevState,
      total: event.total,
      loaded: event.loaded,
    }));
  };

  const handleOpenProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      isProviderModalOpen: true,
    }));
  };

  const handleCloseProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      isProviderModalOpen: false,
    }));
  };

  const updateAttachments = useCallback(
    async (accountPayableId: string, formData: IAccountPayableFormEntity) => {
      const { attachments } = formData;

      if (attachments.length === 0) {
        return Promise.resolve();
      }

      return uploadAttachments({
        attachments,
        onUploadProgress,
        accountPayableId,
      });
    },
    [uploadAttachments],
  );

  const handleSendAccountPayable = useCallback(
    async (formData: IAccountPayableFormEntity) => {
      let serviceFn = postNewAccountPayable;

      if (mode === EFormMode.Edit) {
        serviceFn = updateAccountPayable;
      }

      return serviceFn(formData);
    },
    [mode, postNewAccountPayable, updateAccountPayable],
  );

  const successDialog = useCallback(async () => {
    const isCreating = [EFormMode.Create, EFormMode.Duplicate].includes(mode);

    const modeAction = isCreating ? "cadastrado" : "atualizado";

    return dialog.fire({
      icon: "success",
      title: "Feito!",
      showCancelButton: false,
      confirmButtonText: "Ok",
      html: <>Lançamento de contas a pagar {modeAction} com sucesso.</>,
    });
  }, [dialog, mode]);

  const submitWithNoAttachmentDialog = useCallback(
    async (formData: IAccountPayableFormEntity) => {
      if (formData.attachments.length !== 0) {
        return false;
      }

      const result = await dialog.fire({
        icon: "question",
        title: "Atenção",
        confirmButtonText: "Sim",
        showCancelButton: true,
        cancelButtonText: "Não",
        html: (
          <>
            Você realmente deseja concluir esse lançamento sem <br /> colocar
            pelo menos um anexo?
          </>
        ),
      });

      return result.isDismissed;
    },
    [dialog],
  );

  const handleSubmitMode = useCallback(
    ({ submitMode, newAccountPayableId }: ISubmitHandlerParams) => {
      if (submitMode === EAccountSubmitMode.AddMore) {
        setValue("duplicateAccountPayableId", null);
        navigate(`${SoulRoutes.ACCOUNTS_PAYABLE.path}/new`);
        return;
      }

      if (submitMode === EAccountSubmitMode.Duplicate) {
        setValue("duplicateAccountPayableId", newAccountPayableId);
        navigate(
          `${SoulRoutes.ACCOUNTS_PAYABLE.path}/new?duplicate=${newAccountPayableId}`,
        );
        return;
      }

      navigate(`${SoulRoutes.ACCOUNTS_PAYABLE.path}`);
    },
    [navigate, setValue],
  );

  const handleOnValid = (submitMode: EAccountSubmitMode) => {
    return async (formData: IAccountPayableFormEntity) => {
      if (readonly) {
        return;
      }

      const isDuplicating = mode === EFormMode.Duplicate;
      let assessmentsList = formData.assessments;

      if (isDuplicating) {
        assessmentsList = assessmentsList.filter(
          assessmentObject => assessmentObject.value !== "0",
        );
        setValue("fuspReimbursement", false);
        setValue("assessments", assessmentsList);
      }

      const stopAndAttach = await submitWithNoAttachmentDialog(formData);

      if (stopAndAttach) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        isUploadModalOpen: true,
      }));

      try {
        const newAccountPayableId = await handleSendAccountPayable({
          ...formData,
          assessments: assessmentsList,
        });

        await updateAttachments(newAccountPayableId, formData);

        await successDialog();

        handleSubmitMode({
          submitMode,
          newAccountPayableId,
        });
      } finally {
        setState(prevState => ({
          ...prevState,
          isUploadModalOpen: false,
        }));
      }
    };
  };

  const handleInvalid = useCallback(
    async (errors: FieldErrors<IAccountPayableFormEntity>) => {
      if (readonly) {
        return;
      }

      const errorsValues = Object.values(errors);
      const errorsTypes = errorsValues.map(error => (error as FieldError).type);

      if (errorsTypes.includes("required")) {
        dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Existem campos obrigatórios não preenchidos. Preencha-os para
              adicionar o lançamento de contas a pagar.
            </>
          ),
          showCancelButton: false,
          confirmButtonText: "Ok",
        });

        return;
      }

      if (errors.assessments) {
        const assessmentError = errors.assessments as unknown as FieldError;

        if (assessmentError.type === "assessmentValue") {
          dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: (
              <>
                Os valores de rateio devem bater com o valor da conta a pagar.
              </>
            ),
            showCancelButton: false,
            confirmButtonText: "Ok",
          });

          return;
        }
      }

      if (errors.provider) {
        const providerError = errors.provider as FieldError;

        if (providerError.type === "bankDataRequired") {
          await dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: (
              <>
                Para prosseguir será necessário preencher os dados bancários do
                fornecedor selecionado.
              </>
            ),
            showCancelButton: false,
            confirmButtonText: "Ok",
          });

          handleOpenProviderModal();
        }
      }
    },
    [dialog, readonly],
  );

  const handleBackButtonClick = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      navigate("/accountsPayable");
    }
  };

  return (
    <Container>
      <button
        type="button"
        id="btn-back"
        data-testid="btn-back"
        disabled={isSubmitting}
        className="form-button red-bkg"
        onClick={handleBackButtonClick}
      >
        Voltar
      </button>
      {readonly ? null : (
        <AccountFormSplitButton
          isSubmitting={isSubmitting}
          onClick={submitMode => {
            handleSubmit(handleOnValid(submitMode), handleInvalid)();
          }}
        />
      )}
      <RequestProgressModal
        total={state.total}
        loaded={state.loaded}
        isOpen={state.isUploadModalOpen}
      />
      <ProviderFormModal
        bankDataRequired
        useProvider={useProvider}
        isOpen={state.isProviderModalOpen}
        currentId={memoizedProviderId || ""}
        onRequestClose={handleCloseProviderModal}
      />
    </Container>
  );
}
