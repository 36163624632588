import { toast } from "react-toastify";
import { useCallback, useMemo } from "react";
import { FaCoins, FaEdit, FaLink } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import { SimpleColumn } from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IProjectEntity } from "../../domain/entities/projectEntity";
import { useAllowedProfiles } from "../../../core/presentation/hooks/useAllowedProfiles";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { ProjectsPageModalType } from "../components/ProjectsPage";

export interface ProjectsGridProps {
  handleOpenModal: (info: IProjectEntity, type: ProjectsPageModalType) => void;
  handleToggleProject: (projectId: string, actionText: string) => Promise<void>;
}

export function useProjectsGrid({
  handleOpenModal,
  handleToggleProject,
}: ProjectsGridProps) {
  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();

  const canManageTaxes = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  const canDeleteProject = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  const editBodyTemplate = useCallback(
    (project: IProjectEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          {canManageTaxes ? (
            <button
              type="button"
              className="outline-button tool"
              id={`btn-manage-tax-${rowIndex}`}
              data-testid={`btn-manage-tax-${rowIndex}`}
              data-tip="Gerenciar taxas específicas<br />de créditos"
              onClick={() => {
                handleOpenModal(project, ProjectsPageModalType.Taxes);
              }}
            >
              <FaCoins />
            </button>
          ) : null}
          <button
            type="button"
            data-tip="Editar Projeto"
            id={`btn-edit-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              handleOpenModal(project, ProjectsPageModalType.Crud);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            data-tip="Copiar ID do Projeto"
            id={`btn-copy-id-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-copy-id-${rowIndex}`}
            onClick={() => {
              navigator.clipboard.writeText(project.id);
              toast.success(
                "O ID do Projeto foi copiado para sua área de transferência",
                {
                  autoClose: 2000,
                },
              );
            }}
          >
            <FaLink />
          </button>
        </div>
      );
    },
    [canManageTaxes, handleOpenModal],
  );

  const activeBodyTemplate = useCallback(
    ({ id, active }: IProjectEntity, { rowIndex }) => {
      return (
        <Toggle
          value={active}
          readonly={!canDeleteProject}
          inactiveClassName="toggle-muted"
          id={`btn-tgl-status-${rowIndex}`}
          data-testid={`btn-tgl-status-${rowIndex}`}
          onChange={async _active => {
            const text = !_active ? "desativado" : "ativado";
            const result = await dialog.fire({
              icon: "question",
              title: "Você está certo disso?",
              html: `O Projeto será ${text}. <br /> Deseja prosseguir?`,
              showCancelButton: true,
              cancelButtonText: "Não",
              async preConfirm() {
                dialog.update({
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
                dialog.showLoading();
                await handleToggleProject(id, text);
              },
            });

            if (result.isConfirmed) {
              return _active;
            }

            return !_active;
          }}
        />
      );
    },
    [canDeleteProject, dialog, handleToggleProject],
  );

  const coordinatorTemplate = useCallback(
    (v: IProjectEntity) => (
      <span className={v?.coordinatorName ? "" : "muted"}>
        {v?.coordinatorName || "Não informado"}
      </span>
    ),
    [],
  );

  const openingBalanceTemplate = useCallback(
    (v: IProjectEntity) => (
      <span>
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(v?.openingBalance ?? 0)}
      </span>
    ),
    [],
  );

  const columns = useMemo(
    () =>
      [
        {
          field: "companyGroupName",
          header: "Grupo de Empresa",
          searchable: false,
          orderable: false,
        },
        {
          field: "description",
          header: "Descrição (Sigla)",
          searchable: true,
          orderable: true,
          style: {
            wordBreak: "break-all",
          },
        },
        {
          field: "openingBalance",
          header: "Saldo Inicial",
          searchable: true,
          orderable: true,
          bodyTemplate: openingBalanceTemplate,
        },
        {
          field: "competencyName",
          header: "Competência",
          searchable: true,
          orderable: true,
        },
        {
          field: "coordinatorName",
          header: "Coordenador",
          searchable: true,
          orderable: true,
          bodyTemplate: coordinatorTemplate,
        },
        {
          header: "",
          bodyTemplate: editBodyTemplate,
        },
        {
          field: "active",
          header: "Ativo",
          searchable: false,
          orderable: false,
          bodyTemplate: activeBodyTemplate,
        },
        {
          hidden: true,
          field: "activeDescription",
        },
        {
          hidden: true,
          field: "dateCreated",
        },
        {
          hidden: true,
          field: "courseStartDate",
        },
        {
          hidden: true,
          field: "activitiesStartDate",
        },
        {
          hidden: true,
          field: "activitiesEndDate",
        },
        {
          hidden: true,
          field: "fullName",
        },
        {
          hidden: true,
          field: "deputyProviderName",
        },
        {
          hidden: true,
          field: "projectId",
        },
        {
          hidden: true,
          field: "fuspHash",
        },
      ].map(colParams => new SimpleColumn(colParams)),
    [
      openingBalanceTemplate,
      coordinatorTemplate,
      editBodyTemplate,
      activeBodyTemplate,
    ],
  );

  return {
    columns,
  };
}
