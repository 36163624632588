import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateAccountPayableContract } from "../../domain/contracts/updateAccountPayableContract";
import {
  EAccountPayableDocumentStatus,
  EAccountPayablePaymentMethod,
  EAccountPayableStatus,
  EFuspContractType,
  IAccountPayableFormEntity,
} from "../../domain/entities/accountPayableFormEntity";
import {
  EProviderDocumentType,
  IAccountPayableBaseModel,
  IAccountPayablePayloadModel,
} from "../models/accountPayableModel";
import { IProviderEntity } from "../../domain/entities/providerEntity";

export class UpdateAccountPayableService
  implements IUpdateAccountPayableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async updateAccountPayable(data: IAccountPayableFormEntity): Promise<string> {
    const issueDate = data.issueDate
      ? format(parse(data.issueDate, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      : "";

    const payUntil = data.payUntil
      ? format(parse(data.payUntil, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      : "";

    const terminationDate = data.terminationDate
      ? format(
          parse(data.terminationDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const fuspContractValidityStartDate = data.fuspContractValidityStartDate
      ? format(
          parse(data.fuspContractValidityStartDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const fuspContractValidityEndDate = data.fuspContractValidityEndDate
      ? format(
          parse(data.fuspContractValidityEndDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const providerMetadata = data.provider?.metadata as IProviderEntity;

    const payload: IAccountPayablePayloadModel = {
      id: data.id,
      duplicateAccountPayableId: data.duplicateAccountPayableId,
      active: data.active,
      assessments: data.assessments.map(ass => {
        return {
          // id do rateio
          id: ass.id || "",
          // id da conta a pagar ao qual o rateio está vinculado
          accountPayableId: ass.accountPayableId || "",
          classificationAssessmentId: ass.classificationAssessment
            ?.rawValue as string,
          costCenterId: ass.costCenter?.rawValue as string,
          observation: ass.observation?.replace(/\s+/g, " ")?.trim() || "",
          percentage: ass.percentage,
          value: ass.value,
        };
      }),
      classificationAccountId: data.classificationAccount?.rawValue as string,
      classificationUspId: (data.classificationUsp?.rawValue as string) || null,
      companyId: data.company?.rawValue as string,
      competencyId: (data.competency?.rawValue as string) || null,
      description: data.description?.replace(/\s+/g, " ")?.trim() || "",
      documentNumber: data.documentNumber,
      documentStatus: data.documentStatus?.key as EAccountPayableDocumentStatus,
      issueAsPaid: data.issueAsPaid,
      issueDate,
      observation: data.observation?.replace(/\s+/g, " ")?.trim() || "",
      paymentAccountId: data.paymentAccount?.rawValue as string,
      paymentMethod: data.paymentMethod?.key as EAccountPayablePaymentMethod,
      paymentRequestId: data.paymentRequestId,
      payUntil,
      projectId: (data.project?.rawValue as string) || null,
      providerId: data.provider?.rawValue as string,
      returnStatus: data.returnStatus,
      status: data.status,
      terminationDate,
      value: data.value,
      fuspPurchaseOrderId: data.fuspPurchaseOrderId.length
        ? data.fuspPurchaseOrderId
        : null,
      fuspReimbursement: data.fuspReimbursement || false,
      fuspContractType: data.fuspContractType?.key as EFuspContractType,
      fuspContractValidityStartDate,
      fuspContractValidityEndDate,
      correspondingProviderName: data.correspondingProviderName,
      providerDocumentType: Number(
        providerMetadata.documentType,
      ) as EProviderDocumentType,
    };

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable`;

    let response: IAccountPayableBaseModel;

    // se a conta ja estiver paga, apenas alguns campos que nao
    // afetam saldo podem ser atualizado, por isso utilizamos patch
    if (data.status === EAccountPayableStatus.Paid) {
      response = await this.api.patch<IAccountPayableBaseModel>(url, payload, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }
    // se a conta nao estiver paga, atualizamos todos
    // os campos da conta, por isso utilizamos put
    else {
      response = await this.api.put<IAccountPayableBaseModel>(url, payload, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    const accountPayableId = response.id as string;

    return accountPayableId;
  }
}
