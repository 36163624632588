import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import {
  EAttachmentType,
  attachmentTypeLang,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IValidateImportDataContract } from "../../domain/contracts/validateImportDataContract";
import { ISolicitImportAssessmentEntity } from "../../domain/entities/solicitImportAssessmentEntity";
import { SolicitImportEntity } from "../../domain/entities/solicitImportEntity";
import { ISolicitImportForm } from "../../domain/entities/solicitImportTypes";
import {
  ISolicitImportPayloadModel,
  ISolicitImportResponseModel,
} from "../models/solicitImportModel";
import { MainSolicitImportService } from "./mainSolicitImportService";
import {
  EFuspContractType,
  ESolicitImportDocumentStatus,
  ESolicitImportPaymentMethod,
} from "../../domain/entities/solicitImportEnums";

export class ValidateImportDataService
  extends MainSolicitImportService
  implements IValidateImportDataContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async validateImportData({ imports }: ISolicitImportForm) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PaymentRequestImportations/Validate";

    const payload = imports.map<ISolicitImportPayloadModel>(accountData => {
      return {
        accountAlreadyExists: accountData?.accountAlreadyExists || false,
        active: accountData?.active || false,
        assessments: accountData.assessments.map(assessment => ({
          classificationAssessmentId:
            (assessment.classificationAssessment?.rawValue as string) || "",
          classificationAssessmentName:
            (assessment.classificationAssessment?.label as string) || "",
          costCenterId: (assessment.costCenter?.rawValue as string) || "",
          costCenterName: (assessment.costCenter?.label as string) || "",
          fieldErrors: assessment.fieldErrors,
          hasError: assessment.hasError,
          observation: assessment.observation,
          percentage: assessment.percentage,
          value: assessment.value,
          valueAssessmentLeft: assessment.valueAssessmentLeft,
        })),
        barcode: accountData?.barcode || "",
        classificationAccountId:
          (accountData.classificationAccount?.rawValue as string) || "",
        classificationAccountName:
          accountData.classificationAccount?.label || "",
        companyId: (accountData.company?.rawValue as string) || "",
        companyName: accountData.company?.label || "",
        description: accountData.description,
        destination: accountData.destination,
        documentNumber: accountData.documentNumber,
        documentStatus:
          accountData.documentStatus?.key ||
          ESolicitImportDocumentStatus.Complete,
        documentStatusDescription: accountData?.documentStatus?.value || "",
        fieldErrors: accountData?.fieldErrors || {},
        getCompanyHasProject: accountData.getCompanyHasProject,
        getCompetencyIsUsp: accountData.getCompetencyIsUsp,
        hasAssessmentError: accountData?.hasAssessmentError || false,
        hasError: accountData?.hasError || false,
        id: accountData.id,
        issueDate: this.dateFormatter(accountData.issueDate),
        observation: accountData.observation,
        paymentMethod:
          accountData.paymentMethod?.key || ESolicitImportPaymentMethod.Billet,
        paymentMethodDescription: accountData?.paymentMethod?.value || "",
        payUntil: this.dateFormatter(accountData.payUntil),
        providerId: (accountData.provider?.rawValue as string) || "",
        providerName: accountData.provider?.label || "",
        status: accountData?.status,
        storageFiles: accountData.storageFiles.map(f => ({
          ...f,
          file: f.file || null,
          barcode: f.barcode || "",
          storageFileId: f.storageFileId || "",
        })),
        value: accountData.value,
        valueAssessmentLeft: accountData?.valueAssessmentLeft || 0,
        fuspPurchaseOrderId: accountData.fuspPurchaseOrderId?.toString() || "",
        fuspContractType:
          accountData.fuspContractType?.key || EFuspContractType.NotRpa,
        fuspContractTypeDescription: accountData.fuspContractType?.value || "",
        fuspContractValidityStartDate: accountData.fuspContractValidityStartDate
          ? this.dateFormatter(accountData.fuspContractValidityStartDate)
          : "",
        fuspContractValidityEndDate: accountData.fuspContractValidityEndDate
          ? this.dateFormatter(accountData.fuspContractValidityEndDate)
          : "",
        fuspReimbursement: accountData.fuspReimbursement || false,
      };
    });

    const response = await this.api.post<ISolicitImportResponseModel[]>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    /** É necessário que as linhas inconsistentes sejam as primeiras linhas. */
    const orderedList = response.sort(a => {
      return a.hasError ? -1 : 0;
    });

    const validatedData = orderedList.map(responseData => {
      const datePayUntil = responseData.payUntil
        ? new Date(responseData.payUntil)
        : "";
      const dateIssueDate = responseData.issueDate
        ? new Date(responseData.issueDate)
        : "";

      const dateFuspContractValidityStartDate =
        responseData.fuspContractValidityStartDate
          ? new Date(responseData.fuspContractValidityStartDate)
          : "";

      const dateFuspContractValidityEndDate =
        responseData.fuspContractValidityEndDate
          ? new Date(responseData.fuspContractValidityEndDate)
          : "";

      const company = this.buildTypeaheadObject(responseData, "company");
      const competency = this.buildTypeaheadObject(responseData, "competency");

      if (company) {
        company.metadata = {
          hasProject: responseData.getCompanyHasProject,
        };
      }

      if (competency) {
        competency.metadata = {
          isUsp: responseData.getCompetencyIsUsp,
        };
      }

      const storageFiles =
        responseData?.storageFiles?.map(file => ({
          ...file,
          active: true,
          typeDescription: attachmentTypeLang[file.type],
        })) || [];

      return new SolicitImportEntity({
        id: responseData.id,
        accountAlreadyExists: responseData?.accountAlreadyExists || false,
        assessments:
          responseData?.assessments?.map<ISolicitImportAssessmentEntity>(
            assessment => ({
              hasError: assessment.hasError,
              fieldErrors: assessment.fieldErrors,
              observation: assessment.observation || "",
              value: new BigNumber(assessment.value).toJSON(),
              valueAssessmentLeft: assessment.valueAssessmentLeft,
              costCenter: this.buildTypeaheadObject(assessment, "costCenter"),
              percentage: new BigNumber(assessment.percentage).toJSON(),
              classificationAssessment: this.buildTypeaheadObject(
                assessment,
                "classificationAssessment",
              ),
            }),
          ) || [],
        barcode: this.defineBarcode(responseData),
        classificationAccount: this.buildTypeaheadObject(
          responseData,
          "classificationAccount",
        ),
        company,
        description: responseData?.description?.toUpperCase() || "",
        documentNumber: responseData?.documentNumber?.toUpperCase() || "",
        documentStatus: {
          key: responseData?.documentStatus ?? "",
          value: responseData.documentStatusDescription,
        },
        getCompanyHasProject: responseData.getCompanyHasProject,
        getCompetencyIsUsp: responseData.getCompetencyIsUsp,
        hasAssessmentError: responseData.hasAssessmentError,
        hasError: responseData.hasError,
        issueDate: dateIssueDate ? format(dateIssueDate, "dd/MM/yyyy") : "",
        observation: responseData?.observation?.toUpperCase() || "",
        payUntil: datePayUntil ? format(datePayUntil, "dd/MM/yyyy") : "",
        paymentMethod: {
          key: responseData?.paymentMethod ?? "",
          value: responseData.paymentMethodDescription,
        },
        provider: this.buildTypeaheadObject(responseData, "provider"),
        storageFiles,
        value: responseData.value,
        valueAssessmentLeft: responseData.valueAssessmentLeft,
        fieldErrors: responseData.fieldErrors,
        fuspPurchaseOrderId: responseData.fuspPurchaseOrderId?.toString() || "",
        fuspContractType: {
          key: responseData?.fuspContractType ?? "",
          value: responseData.fuspContractTypeDescription,
        },
        fuspContractValidityStartDate: dateFuspContractValidityStartDate
          ? format(dateFuspContractValidityStartDate, "dd/MM/yyyy")
          : "",
        fuspContractValidityEndDate: dateFuspContractValidityEndDate
          ? format(dateFuspContractValidityEndDate, "dd/MM/yyyy")
          : "",
        fuspReimbursement: responseData.fuspReimbursement,
      });
    });

    return validatedData;
  }

  private defineBarcode(data: ISolicitImportResponseModel) {
    if (data?.barcode) {
      return data.barcode;
    }

    const billetAttachment = data?.storageFiles?.find(attachment => {
      return Number(attachment?.type) === EAttachmentType.Billet;
    });

    return billetAttachment?.barcode || "";
  }
}
